.App {
  /* text-align: center; */
  /* display: flex; */
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(1, 0, 0);
}

.App-link {
  color: #61dafb;
}
.table-header {
  font-weight: bold !important;
}
/* .App .side-bar-btn{
  display: block;
} */
.Custom-sidebar-clr .MuiDrawer-root .MuiPaper-root.MuiPaper-elevation {
  background-color: #013365;
}
.custom-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
}

button.btn-gld:disabled {
  color: #333 !important;
  background: #ccc !important;
}

button.asbtn-gld:disabled {
  color: #333 !important;
  background: #ccc !important;
}

button.usbtn-gld:disabled {
  color: #333 !important;
  background: #ccc !important;
}

.MuiButton-textPrimary.logoutBtn {
  font-weight: 800 !important;
  padding: 10px 0;
}

.logoContainer img {
  margin-right: 30px;
  max-height: 70px;
}
.logoContainer {
  display: flex;
}

.logoRightHeader .logoRight {
  padding-right: 50px;
}
.logoRightHeader .logoRight img {
  max-height: 35px;
  margin: 0 10px;
}
.logoRightHeader {
  padding: 5px 0 0;
  display: flex;
}
.mandatory {
  color: #cc0000;
}
.loaderBtn {
  margin-left: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #c1c1c1;
  overflow-x: auto;
}

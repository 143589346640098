.main-mail-div {
  width: 100%;
}

.Custom-TextFieldbox p {
  margin: 15px 0;
  font-size: 15px;
  font-weight: 600;
}

.Custom-TextFieldbox .MuiFormControl-root input {
  border-radius: 10%;
}

.Custom-textfield,
.Custom-textfield1 {
  width: 100%;
  overflow: auto;
}

.Custom-TextFieldbox {
  margin: 15px 0;
}

.Custom-textfield1 input {
  height: 300px;
  width: 100%;
  overflow: auto;
}

.custom-mail-box {
  width: 100%;
  max-height: 400px;
  overflow: auto;
}

.send-btn {
  background-color: #013365 !important;
  /* margin-top: 1% !important; */
}

.custom-heading {
  color: black !important;
  background-color: transparent !important;
}

.custom-div-radio {
  margin: 3.5% 0 !important;
  background-color: #f6f8fa;
  padding: 5px 20px;
  max-height: 670px !important;
  overflow: auto;
}

.custom-div-radio>div {
  margin: 0px 0;
}

.custom-divider-email {
  width: 100%;
  margin-top: 2% !important;
}

.radio-box {
  margin-top: 2% !important;
}

.first-grid-box {
  width: 100%;
  background-color: #f6f8fa;
}

.custom-div-grid1 {
  background-color: #f6f8fa;
  min-height: 625px !important;
  padding: 5px 20px;
}

.profile-picture {
  max-height: 200px;
}

.custom-pic-box {
  display: flex;
  justify-content: left;
  padding: 10px 30px 10px 0;
}

.custom-heading-box {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
}

.first-grid-headings {
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.first-grid-text {
  margin-bottom: 10px !important;
  word-wrap: break-word !important;
}

.head-blue-text {
  color: "#0257AB";
}

.custom-space{
  margin-top: 20px!important;
}
.custom-main-div {
  width: 100%;
}

table.rounded-corners {
  border-spacing: 0;
  border-collapse: separate;
}
.table-box-header {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  /* height: 612px; */
}

.table-box {
  justify-content: center;
}

.custm-table-box1 {
  width: 40%;
  flex-basis: 60% !important;
  margin-right: 20px !important;
  margin-top: 2% !important;
}

.custom-box1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rounded-table th,
.rounded-table td {
  padding: 5px;
  border: 1px solid black;
}
.rounded-table th {
  background-color: #f0f0f0;
}

.bgc {
  width: 100%;
}

/* .profile-pic {
  width: 280px;
  height: 285px;
} */

.profile-pic-container {
  width: 100%;
  height: 0;
  padding-top: 102.14%; /* The percentage height is based on the width (285/280 = 1.01786, i.e., 102.14%) */
  position: relative;
}

.profile-pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure that the image covers the padded area, useful for different aspect ratios of images */
}

.bgc > div > img {
  padding: 17px;
}

.main-div-radio {
  /* padding-right: 112px !important;     */
  margin: 6% 0 !important;
  background-color: #f6f8fa;
  min-height: 450px !important;
  padding: 10px 20px;
}
.main-div-radio > div {
  margin: 10px 0;
}

.box-hdr-txt {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MuiAccordion-region .custom-sec-box {
  width: unset;
}


.custom-heading {
  font-weight: 700 !important;
  font-size: 23px !important;
  margin-bottom: 12px!important; /* Space between heading and content */
}


.MuiBox-root .MuiDivider-root.custom-divider {
  width: 100%;
  margin: 24px 0;
  color: #e9e9e9;
}

.activities-box {
  width: 100%;
}

.work-exp-box {
  width: 100%;
}

.custom-accordion-div .MuiPaper-root {
  margin: 10px 0 !important;
}


.table-linkedin-srch {
  color: #343434 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}

/* new code */
.MuiTableCell-sizeMedium {
  padding: 15px 20px; /* Larger padding for readability */
}

@media (max-width: 600px) {
  .MuiTableCell-sizeMedium {
      padding: 8px 10px; /* Smaller padding on smaller screens */
  }
}

/* .bgc {
  padding: 20px;
} */

@media (max-width: 768px) {
  .bgc {
      padding: 10px; /* Less padding on smaller screens */
  }
}

.accordion-box {
  margin-top: 10px; /* Space above accordion */
}

@media (max-width: 480px) {
  .profile-pic-container {
      padding-top: 120%; /* Increase aspect ratio for narrower screens */
  }

  .profile-pic-table {
      margin-top: 10px; /* Smaller gap on smaller screens */
  }

  .custom-heading {
      font-size: 1rem; /* Smaller font on smaller screens */
  }
}


@media (max-width: 480px) {
  .main-div-radio {
      padding: 10px; /* Less padding on smaller screens */
  }
}

.MuiTableRow-root .custom-divider-table1 {
  border: 1px solid #dedede;
  margin: 7px 2px 0 49px;
  width: 1px;
}

.MuiTableRow-root .custom-divider-table2 {
  border: 1px solid #dedede;
  /* display: flex;
  flex-direction: column; */
  justify-content: end;
  margin: 7px 2px 0 15px;
  width: 1px;
}

.MuiTableRow-root .custom-divider-table3 {
  border: 1px solid #dedede;
  /* display: flex;
  flex-direction: column; */
  justify-content: end;
  margin: 7px 2px 0 365px;
  width: 1px;
}

.MuiTableRow-root .custom-divider-table4 {
  border: 1px solid #dedede;
  /* display: flex;
  flex-direction: column; */
  justify-content: end;
  margin: 7px 2px 0 165px;
  width: 1px;
}

.page-text {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
}

.slider-txt {
  width: 100%;
}

.slider-txt-box {
  width: 99%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.comp-box-header {
  display: flex;
  justify-content: center;
  padding: 0 30px;
}

.table-box {
  justify-content: center;
}

.custom-grid-box1 {
  width: 40%;
  flex-basis: 40% !important;
  margin-right: 100px !important;
  margin-top: 33px !important;
}
.comp-box-header .table-box .custom-grid-box1 {
  padding-left: 0 !important;
}

.custom-grid-box2 {
  margin-top: 66px !important;
  display: flex;
  flex-direction: column !important;
}
.custom-grid-box2 > div {
  width: 100%;
}
.custom-input .css-10botns-MuiInputBase-input-MuiFilledInput-input {
  height: 230px !important;
}
.custom-input > div > textarea {
  height: 235px !important;
}

.heading-custom {
  display: flex;
  flex-direction: row;
}

.custom-virt-divider {
  margin-top: 80px !important;
  height: 100% !important;
  margin-right: 50px !important;
}

.custom-select-drpdn {
  width: 100%;
}

.custom-menu-item {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.custom-slect-text {
  padding: 0 0 0 8px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-btn {
  display: flex;
  justify-content: right;
  margin-top: 5%;
}

.generate-mail-btn {
  background-color: #013365 !important;
}

.custom-main-box {
  display: flex;
  flex-direction: column;
  width: 99%;
}
.custom-main-box .custom-slider-minvalue {
  width: 96%;
  margin-left: 12px;
}
.custom-dropdown-box .MuiTypography-root {
  padding: 0 !important;
}

.custom-dropdown-box .MuiFormControl-root {
  margin: 8px 0 !important;
}

.custom-main-box div > .MuiFormControl-root {
  width: 100%;
}
.custom-input > .MuiInputBase-root {
  padding: 9px 9px 9px;
}

.custom-slider-minvalue .css-yafthl-MuiSlider-markLabel .pinter-hover {
  left: 50% !important;
}

.custom-dropdown-box {
  margin-top: 3%;
}

@import url("https://fonts.googleapis.com/css2?family=Abel&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap");

@import './GlobalCSSVariables.scss';
@import './GlobalSCSSFunctions.scss';

html {
  height: 100%;
}

.app-bgColor{
  background: $pageBackgroundColor;
  min-height: 100vh;
  height: 100%;
}

.AppPageHeading{
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: $robotoFont !important;
}

.verifi-success-icon{
  color: $success_color !important
}

@mixin fontStyle($weight, $size, $height) {
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

@mixin margin-x($size) {
  margin-left: $size;
  margin-right: $size;
}
@mixin margin-right($size) {
  margin-right: $size;
}
@mixin margin-left($size) {
  margin-left: $size;
}
@mixin margin-y($size) {
  margin-top: $size;
  margin-bottom: $size;
}
@mixin padding-x($size) {
  padding-left: $size;
  padding-right: $size;
}
@mixin padding-y($size) {
  padding-top: $size;
  padding-bottom: $size;
}
@mixin padding-right($size) {
  padding-right: $size;
}

@mixin card-shadow-radius() {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius;
}

@mixin v2btnPrimary ($fontFamily, $fonSize, $fontStyle, $fontWeight, $lineHeight){
  font-family: $fontFamily !important;
  font-size: $fonSize !important;
  font-style: $fontStyle !important;
  font-weight: $fontWeight !important;
  line-height: $lineHeight !important;
}
.MuiPaper-elevation8 {
 box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.42) !important;
}
.candidate-feedback {
  padding-left: 25px;
}

.css-ja5taz-MuiTooltip-tooltip {
  transform-origin: center top;
  margin-top: -5px !important;
}
body {
  color: $normalFontColor;
  font-family: $robotoFont;
}
.css-1vvpa3k .MuiFormControl-root {
  margin-top: 5px !important;
}
.no-margin{
  margin-top: 1px !important 
}

.MuiList-root,
.MuiTypography-root,
.MuiButton-root,
.MuiButtonBase-root-MuiTab-root {
  font-family: $robotoFont;
}

.MuiButtonBase-root {
  &.text-black {
    .MuiTypography-root {
      color: #000;
    }
    &:hover {
      .MuiTypography-root {
        color: #fff;
      } 
    }
  }
}
#container_2 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.appBtn{
  font-family: $robotoFont !important;
  font-size: 14px !important;
  white-space: nowrap;
}

button:hover,
.MuiMenu-list li:hover,
.MuiButton-text:hover {
  background-color: $primaryColor !important;
  color: #fff;
}
.myearning-button:hover {background-color:transparent !important}


.primary-color-text,
.MuiCheckbox-root {
  color: $primaryColor !important;
}


.error{
  color: $mandate_color;
  font-family: $robotoFont !important;
}

.v2LastLeft{
  font-size: 14px !important;
  font-family: $robotoFont !important;
  font-weight: 600 !important;
}
// Breadcrumbs CSS end here

.defaultAppTimePicker .MuiIconButton-edgeEnd{
  padding: 4px !important;
}

.defaultAppTimePicker .MuiIconButton-edgeEnd svg{
  font-size: 1.2rem !important;
}
.public-page-content{
  background-color: $primaryColor;
  height: 100%;
}

.SubmissionFilterLabel .MuiFormControlLabel-label {
  margin-left: 8px;
}

.ackn-scorecard-wrapper {
  margin-bottom: 150px !important;
}

.recruiter-info-ackn{
  padding-bottom: 28px;
  font-family: $abelFont;
  font-size: 14px;
  font-weight: 400;

}

img.logo {
  width: 140px;
}

.text-center{
  text-align: center;
}
.acknAuthorizedBtn{
  margin-right: 5px !important;
}

.helpInfoIcon button.MuiButtonBase-root.MuiFab-root {
  color: $primaryColor;
}

.helpInfoIcon button.MuiButtonBase-root.MuiFab-root:hover{
  color: #fff;
}

.boldface {
  font-weight: 600 !important;
}
.bold{
  font-weight: 700 !important;
}
.abelh2 {
  @include fontStyle(normal, 42px, 61px);
  @media screen and (max-width: 767px) {
    @include fontStyle(normal, 36px, normal);
}
@media screen and (max-width: 575px) {
  @include fontStyle(normal, 32px, normal);
}
@media screen and (max-width: 480px) {
  @include fontStyle(normal, 28px, normal);
}
}

.mt-0 {
  margin-top: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.MuiSvgIcon-root.icon-color-default,
.MuiSvgIcon-root.icon-color-default svg {
  fill: $normalFontColor;
}
.d-none {
  display: none;
}

.static-width-chip .MuiChip-root {
  width: 150px;
  border-radius: $border-radius;
}
.btn-signup{
  padding: 9px 30px !important;
  font-size: 0.875rem !important;
}
.v2-confirmPopupTitle{
  color: $primaryColor !important;
  text-align: center;
  font-family: $robotoFont;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.v2DialogActionSection{
  justify-content: center !important;
}

.V2-textArea{
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  /* font-size: 1rem; */
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.ClientSignupFormBanner{
  background: #fff;
  padding: 16px;
}

.V2-textArea:focus, .V2-textArea:active{
  border-bottom: 1px solid #5553b7 !important;
}
textarea,
.text-area2 {
  width: 100%;
  border: none;
  font-size: 1rem;
  font-family: $robotoFont !important;
}
.text-area {
  display: inline-flex;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  font-size: 1rem;

  &::before {
    left: 0;
    right: 0;
    bottom: -5px;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }
  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
  }

  &:hover::before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }
}

.text-area2 {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .MuiInput-underline {
    &::before,
    &::after {
      display: none;
    }
  }
}
.unorder-list {
  padding-left: 20px;

  li {
    padding-left: 10px;
    padding-bottom: 10px;
    font-family: $abelFont;
    font-size: 1rem;
  }
}

.manageProfileMainHeading{
  color: $light_dark_black;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
}

.manage-accounts-tabs-Panel {
  position: relative;
}


.manage-profile-btns {

  @include min_width_devices(900px){
    position: absolute;
    right: 0;
    top: 0;
  }

  @include max_width_devices(899px){
    position: relative;
    text-align: center !important;
  }
  
}

.ManageProfileActionBtnToolbar{
  @include max_width_devices(899px){
    text-align: center !important;
  }
}

.heading-underline {
  color: $light_dark_black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 7px;
  margin-bottom: 15px !important;
}

.Mui-disabled .radio-sml svg {
  color: $disabledColor;
}

.radio-sml svg{
  color: $primaryColor;
}

.MuiButton-root.MuiButton-containedSecondary {
  background-color: $secondaryColor;
  color: $primaryColor;

  &:hover {
    color: #fff;
  }
}

.MuiBox-root.main-menu {
  .MuiButton-root {
    @include margin-x(10px);
    @include padding-x(5px);

    &:hover {
      background-color: transparent !important;
      color: $primaryColor;
      font-weight: 600;
    }
  }
}
.MuiBox-root.header-icon-menu {
  .MuiButtonBase-root {
    color: $primaryColor;
    svg {
      color: $primaryColor;
    }

    &:hover {
      background-color: transparent !important;
      color: $primaryColor;
      font-weight: 600;
    }
  }
}

.MuiModal-root#menu-appbar {
  .MuiMenuItem-root {
    .MuiListItemIcon-root {
      color: $primaryColor;
    }
    &:hover {
      background-color: $secondaryColor !important;

      .MuiTypography-root {
        color: #000;
      }
    }
  }
}


.Notification-Switch .MuiSwitch-thumb{
  width: 16px !important;
  height: 16px !important;
}
.MuiTypography-root {
  &.abel {
    font-family: $abelFont;
  }
  &.abelh2 {
    @include fontStyle(normal, 32px, normal);
    color: $normalFontColor;
    @media screen and (max-width: 767px) {
      @include fontStyle(normal, 28px, normal);
  }
  @media screen and (max-width: 575px) {
    @include fontStyle(normal, 26px, normal);
  }
  @media screen and (max-width: 480px) {
    @include fontStyle(normal, 24px, normal);
  }
  }
  &.abelh3 {
    @include fontStyle(normal, 26px, 41px);
    color: $normalFontColor;
    @media screen and (max-width: 767px) {
      @include fontStyle(normal, 24px, normal);
  }
  @media screen and (max-width: 575px) {
    @include fontStyle(normal, 22px, normal);
  }
  @media screen and (max-width: 480px) {
    @include fontStyle(normal, 20px, normal);
  }
  }
  &.customHead{
    @include fontStyle(700, 48px, normal);
    font-family: $robotoFont !important;
    @media screen and (max-width: 767px) {
      @include fontStyle(700, 42px, normal);
  }
  @media screen and (max-width: 575px) {
    @include fontStyle(700, 38px, normal);
  }
  @media screen and (max-width: 480px) {
    @include fontStyle(700, 34px, normal);
  }
  }
  &.text-link {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.successTextColor{
  color: rgba(38, 194, 150, 1);
}

// Common Popup css start

.PopupStatusIcon{
  width: 60px;
}
.PopupPrimaryTitle{
  font-family: $robotoFont;
  text-align: center;
  font-size: 28px !important;
  font-weight: 700 !important;
  color: $primaryColor;
  line-height: normal;
}

.PopupSecondaryTitle{
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal;
}
// Common Popup css end
// common layout css start here

.helpTextHeadingLogin{
  margin: 64px !important;
  font-family: $robotoFont !important;

  @include max_width_devices($mui_md_breakpoint){
    margin: 32px !important;
    font-size: 1.3rem !important;
  }

  @include max_width_devices($mui_sm_breakpoint){
    margin:8px !important;
    font-size: 1.3rem !important;
    
  }
}
.candidates-label {
  margin: 0 !important;
  padding: 0 0 0 3px;
}

.candidates-label > span{
  padding-right: 10px;
  font-size: 14px;
}

.durLabel span {
  font-size: 14px;
}

.candidateClearAllBtn {
  width: 70px;
  margin: 0px 18px 0 0 !important;
  padding: 0 !important;
}

.cand-filter-btnWrapper{
  display: flex;
  justify-content: space-between;
}

.ndf{
  margin-left: 10px;
}
.icn-search-wrapper {
  padding: 4px !important;
}

.page-contentArea{
  overflow-x: auto;
  min-height: 480px;
}

.responsive-infinite-scroll-cls1{

  @include max_width_devices($mui_xl_breakpoint){
    height: 75vh !important;
  }

  @include max_width_devices($mui_lg_breakpoint){
    height: 74vh !important;
  }

  @include max_width_devices($mui_md_breakpoint){
    height: 68vh !important;
  }

  @include max_width_devices($mui_sm_breakpoint){
    height: 68vh !important;
  }

  @include max_width_devices($mui_xs_breakpoint){
    height: 68vh !important;
  }
}

// button css start

.v2-base-btn{
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: $light_dark_black !important;
}

.v2-base-btn:hover{
  color: #fff !important;
}

.disable-v2-base-btn{
  color: rgba(0, 0, 0, 0.26) !important;  
}

.v2h3{
  font-weight: normal !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #000;
}

.notificationWrapper{
  float: left;
}
.custPrimary{
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.custBtnPrimary{
 @include v2btnPrimary($robotoFont, 14px, normal, 500, 20px)
}

.custBtnPrimaryV2{
  @include v2btnPrimary($robotoFont, 16px, normal, 500, 20px);
  padding: 8px 20px !important;
 }

.custNoBorderBtn{
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px !important;
}
// button css end

// input field start css

.phoneInputLabel{
  text-align: left;
    display: inline-block;
     margin-top: 5px;
    font-family: $robotoFont;
    color: grey;
    font-size: 12px;
}

.custPhoneInput .react-tel-input .form-control{
  height: 30px !important;
}
//input field end css 

// snackbar css start

.snackbarSuccess {
  color: $snackbar_success !important;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  border-radius: 10px !important;
  background: $snackbar_bg !important;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),
   0px 6px 10px 0px rgba(0,0,0,0.14),
   0px 1px 18px 0px rgba(0,0,0,0.12) !important;
}

.snackbarSuccess .MuiAlert-icon{
color: #fff !important;
}
//snackbar css end

// page css start

.addEditTeamMemberPageTitle{
  padding-top: 8px !important;
  padding-bottom: 16px;
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

// page css end

// commom layout css end here

.MyMargin{
    margin: -14px 2px -18px 3px !important;

}

.gotoTopBtn{
  position: fixed;
  right: 6%;
  z-index: 50;
  top:81%;
}

.MuiList-root.login-intro-list {
  li {
    .MuiTypography-root {
      font-family: $abelFont;
      color: $normalFontColor;
      @include fontStyle(normal, 18px, 30px);
    }

    .MuiListItemIcon-root {
      min-width: 45px;
    }
  }
}

.LeftNavListWrapper {
  padding-top: 15px;
  background-color: #4540db !important;
}
.MuiDrawer-paperAnchorLeft{
  background-color: #4540db !important;

}

.css-zxdg2z{
  padding: 0!important;
}

.desktopMenuIconbtn{
  color: #fff;
}

.leftNavigationMenu .css-38xnma-MuiList-root {
  background-color: #4540db;
  padding-top: 3px;
}

.main-header .css-1djqib4-MuiButtonBase-root-MuiButton-root, .main-header .css-fsh3i8-MuiButtonBase-root-MuiButton-root{
  margin-top: 13px;
  margin-bottom: 13px;
}

a.TopHeaderMainLogo img {
  vertical-align: text-top;
  margin-left: -15px;
  margin-top: -8px;
}

.isMobileMenuActive{
  width: 100% !important;
}

.MobileMenuNavigarDrawer .MuiDrawer-paper, .MobileMenuNavigarDrawer .css-4t3x6l-MuiPaper-root-MuiDrawer-paper, .MobileMenuNavigarDrawer .css-38xnma-MuiList-root 
{
background-color: $primaryColor;
}

.MobileMenuNavigarDrawer .MuiDrawer-paper, .MobileMenuNavigarDrawer .css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  color:#fff;
  margin-top: 64px;

  @include max_width_devices($mui_sm_breakpoint){
    margin-top: 54px;
  }
}

.MobileMenuNavigarDrawer-NonSigned  .MuiDrawer-paper, .MobileMenuNavigarDrawer-NonSigned .css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  margin-top: 0px !important;
}

.MobileMenuNavigarDrawer {
  color: #fff;
}

.MobileMenuNavigarDrawer .css-662k2t-MuiList-root{
  overflow: auto;
  padding-bottom: 64px;
}

.login-banner-right,
.signup-right,
.submit-resume-form {
  .MuiTypography-root {
    &.abelh2 {
      letter-spacing: 0.15em;
      color: #4f4f4f;
    }

    &.letter-spacing-normal {
      letter-spacing: 0;
    }

    &.signup-with {
      margin-top: 32px;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      color: rgba(53, 53, 53, 1);
      font-family: $robotoFont !important;
      font-weight: 400 !important;
      font-size: 16px !important;

      .MuiButton-root {
        min-width: 24px;
        padding: 0;
        transition: top ease 0.5s;
        top: 0;

        &:hover {
          top: -3px;
          background-color: transparent !important;
        }
      }

      .google-login-btn {
        min-width: 24px;
        padding: 0;
        transition: top ease 0.5s;
        top: 0;
        position: relative;

        &:hover {
          top: -3px;
          background-color: transparent !important;
        }
      }

      svg {
        color: $primaryColor;
        position: relative;
      }

      .linkedin-icon {
        border-radius: 100%;
        overflow: hidden;
        font-size: 1.2rem;
      }

      .linkedin-btn{
        background: white;
        border: 1px solid #dadce0;
        padding:5px;
        margin-left: 15px;
        margin-right: 10px;
      }
     
    }
  }
  .MuiButton-root.signup-button {
    border-radius: $border-radius;
    font-size: 1.1rem;
    font-family: $robotoFont;
    padding: 10px 20px;
    line-height: 1.2;
    text-transform: none;
    margin-top: 30px;
    margin-bottom: 30px;
   
  }
  .mui-svg-comments{
    display: flex;
    align-items: center;
  }

  
  .MuiButton-root.button-large {
    border-radius: $border-radius;
    font-size: 1.3rem;
    font-family: $abelFont;
    padding: 12px 25px;
    line-height: 1.2;
    text-transform: none;
    margin-top: 30px;
    margin-bottom: 30px;

    &.large {
      font-size: 1.3rem;
    }
  }

  .MuiFormControlLabel-labelPlacementEnd {
    .MuiButtonBase-root {
      position: relative;
      top: -4px;
    }
    .MuiFormControlLabel-label {
      font-size: 0.8rem;
      font-family: $robotoFont;
    }
  }
  $medium: 600px;
  .MuiFormControl-root.payrate-input {
    
   @media only screen and (max-width: 667px) {
    width: 100% !important;
  }
    width: 75px !important;

    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-right: 70px !important;
  }
  .MuiButton-root.upload-btn {
    @include padding-x(20px);
    font-size: 1.2rem;
    border-radius: $border-radius;
    margin-left: 30px;
    margin-top: 3px;
    text-transform: none;

    &.btn{
      padding-left: 13px;
    }
    svg {
      font-weight: 400;
      font-size: 1.2rem;
      margin-right: 10px;
    }
  }
}

.mainLoginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 88vh;
}

.InnerLoginWrapper{
  background-color: $light-blue-color;
  padding: 25px;
  margin: 20px;
  max-width: 30vw;

  @include max_width_devices($mui_sm_breakpoint){
    width: auto;
    max-width: unset;
  }
}

.adminSignHeading{
  line-height: 42px !important;
}

.login-right-section {
  padding-top: 0!important;
  
  .login-banner-right {
    .login-forms {
      background-color: $light-blue-color;
      padding: 20px 30px 40px;
      button,.signup-btn{
        text-transform: none;
      }

      @include max_width_devices($mui_sm_breakpoint){
        padding: 15px;
      }
    }
  }
  padding-left: 15px !important;

  @media screen and (min-width: 767px) {
    padding-left: 0 !important;
  }
}

.login-shadow {
  box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.10);
}

.MuiTypography-root.label-sml {
  font-size: 1rem;
  top: 4px;
  position: relative;
  display: inline-block;
  @media screen and (max-width: 767px) {
    width: 100%;
    top: 0;
  }
}

.MuiTypography-root.label-sml-recruitersignup {
  font-size: 1rem;
  top: 0px;
  position: relative;
  display: inline-block;
  @media screen and (max-width: 767px) {
    width: 100%;
    top: 0;
  }
}


.MuiBox-root.form-three-child {
  .MuiFormControl-root {
    width: 31.3%;

    &.linkedIn-input {
      width: 50%;
    }
    &.experience-input {
      width: 23%;
    }

    &:nth-child(2) {
      @include margin-x(3%);
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      &:nth-child(2) {
        @include margin-x(0px);
      }
    }
  }

  &.space-between {
    .MuiFormControl-root {
      &:nth-child(2) {
        @include margin-x(0px);
      }
    }
  }
}
.MuiBox-root.form-four-child {
  .MuiFormControl-root {
    width: 30.3%;

    &.state-input,
    &.zip-input {
      width: 15%;
    }

    &:nth-child(2) {
      @include margin-x(3%);
    }
    &:nth-child(3) {
      margin-right: 3%;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
      &:nth-child(2){
        width: 100%;
      }
      &:nth-child(3){
        width: 100%;
      }
    }
  }

  &.space-between {
    .MuiFormControl-root {
      &:nth-child(2) {
        @include margin-x(0px);
      }
    }
  }
}

.MuiBox-root.form-upload .MuiFormControl-root {
  width: 50%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.MuiBox-root.form-upload-resume .MuiFormControl-root {
  width: 30%;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.custom-email-verf-icon{
  padding-left: 10px;
  width: 20px;
  margin-top: 5px;
}
.signup-right{
  .phone-number-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    &:nth-child(1){
      @include padding-right(2%);
    }
    @media screen and (max-width: 1199px) {
      justify-content: flex-start;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }  
    @include max_width_devices ($custom_breakpoint_480){
      flex-direction: row;
    }
    
    .phone-verify-btn{
      button{
        font-size: 12px;
        @include max_width_devices($mui_sm_breakpoint){
          margin-top: 0px !important;
        }

        @include max_width_devices($custom_breakpoint_480){
          margin-top: 22px !important;
        }
      }
      @media screen and (max-width: 600px) {
        text-align: right;
        margin-top: 10px;
      }
    }
  }
  .MuiBox-root.form-three-child {
    .MuiFormControl-root {
      width: 50%;
      
      @media screen and (max-width: 767px) {
        width: 49%;
          @include padding-right(10px);
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  
      &.linkedIn-input {
        width: 50%;
        @media screen and (max-width: 480px) {
          width: 100%;
        }
        
      }
      &.experience-input {
        width: 23%;
        @media screen and (max-width: 767px) {
          width: 100%;
    }
      }
  
      &:nth-child(2) {
        @include margin-x(3%);
        @media screen and (max-width: 767px) {
          @include margin-x(0);
    }
      }
  
      @media screen and (max-width: 480px) {
        width: 100%;
        &:nth-child(2) {
          @include margin-x(0px);
        }
      }
    }
    .checkbox-sml{
      @include margin-x(5px);
    }
    &.space-between {
      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
      .MuiFormControl-root {
        &:nth-child(1){
          @include padding-right(2%);
          @media screen and (max-width: 767px) {
            @include padding-right(10px);
      }
        }
        &:nth-child(2) {
          @include margin-x(0px);
        }
      }
    }
    &.name-wrapper{
        .MuiFormControl-root {
          width: 31.3%;
          @media screen and (max-width: 575px) {
            width: 30.99%;
          }
          @media screen and (max-width: 480px) {
            width: 100%;
          }
    }
  }
  }
  .MuiBox-root.form-four-child {  
    .MuiFormControl-root {
      width: 30.3%;
      @media screen and (max-width: 767px) {
            width: 50%;
      }
      .MuiInputBase-root{
        @media screen and (max-width: 767px) {
          @include margin-right(3%);
    }
      }
  
      &.state-input,
      &.zip-input {
        width: 15%;
        .MuiInputBase-root{
          @media screen and (max-width: 767px) {
            @include margin-right(0);
      }
        }
        @media screen and (max-width: 767px) {
          width: 50%;
         
    }
      }
  
      &:nth-child(2) {
        @include margin-x(3%);
        @media screen and (max-width: 767px) {
          @include margin-x(0);
    }
      }
      &:nth-child(3) {
        margin-right: 3%;
        @media screen and (max-width: 767px) {
          @include margin-x(0);
    }
    .MuiInputBase-root{
      @media screen and (max-width: 767px) {
        @include margin-right(3%);
  
  }
    }
      }
      &:nth-last-child(1){
        .MuiInputBase-root{
          @media screen and (max-width: 767px) {
            @include margin-right(0);
  
      }
        }
      }
     
      @media screen and (max-width: 480px) {
        width: 100%;
        &:nth-child(2){
          width: 100%;
        }
        &:nth-child(3){
          width: 100%;
        }
      }
    }
  
    &.space-between {
    
      .MuiFormControl-root {
        &:nth-child(2) {
          @include margin-x(0px);
        }
      }
    }
  }
}

// add css for login banner component

.banner-typography-text {
  padding: 25px;
}

.forgotpwd-banner-wrapper {
  background-color: $primaryColor;
  color: #fff;
  height: 100%;
  
  @include max_width_devices($mui_md_breakpoint){
    margin-bottom: 30px;
  }
}

.forgotpwd-banner-typography-text {
  padding: 25px;
  flex-grow: 1;
}

.loginFormWrapper{
  background-color: $light_gray_bg_color;
}

.SignFormMainWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
//end css for login banner component

.MuiList-root.signup-next-steps {
  padding: 0 40px;
  margin-top: 30px;
  align-items: flex-start;

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  .MuiAvatar-root {
    background-color: #fff;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    margin-top: 4px;
  }

  .MuiListItemText-root {
    margin-top: 0;
  }

  li {
    min-height: 150px;
    align-items: flex-start;
    padding-top: 5px;

    &:last-child {
      background: none;
    }

    .title {
      color: #000;
      font-size: 1.2rem;
    }

    .txt {
      line-height: 18px;
      color: #848fa3;
    }
  }
}

.MuiBox-root.faq-accordion {
  border-top: 2px solid #d7def0;
  border-bottom: 2px solid #d7def0;

  * {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  .MuiAccordion-root {
    box-shadow: none;

    &:before {
      background-color: #d7def0;
      height: 2px;
    }

    &.Mui-expanded {
      margin: 8px 0;
      &:before {
        opacity: 1;
      }
    }

    .MuiAccordionDetails-root,
    .MuiAccordionSummary-root {
      @include padding-x(0);
    }

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 45px;

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
      }
    }

    .MuiAccordionSummary-root {
      .MuiTypography-root {
        font-weight: 600;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      color: $primaryColor;

      &.Mui-expanded {
        -webkit-transform: rotate(-134deg);
        -moz-transform: rotate(-134deg);
        -ms-transform: rotate(-134deg);
        transform: rotate(-134deg);
      }
    }
  }
}

.showHideIcon {
  padding: 4px;
}

.MuiGrid-root.dashboard-sml-cards {
  .MuiPaper-root {
    padding: 0;

    .dashboard-sml-card {
      background: #7368f9;
      border-radius: $border-radius;
      padding: 0;
      transition: top ease 0.5s;
      position: relative;
      top: 0;
      box-shadow: none;
      color: #fff;

      &:hover {
        top: -5px;
      }

      .MuiTypography-root {
        color: #fff;
        text-transform: none;
      }

      .MuiCardContent-root {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.bg1 {
        background: llinear-gradient(89.69deg, #7368f9 2.03%, #273dff 99.73%);
      }
      &.bg2 {
        background: linear-gradient(270deg, #003ad2 0%, #0097ec 100%);
      }
      &.bg3 {
        background: linear-gradient(270.23deg, #8c83fa 0.19%, #5767fb 99.8%);
      }
      &.bg4 {
        background: linear-gradient(269.97deg, #5900c9 0.02%, #9852f0 99.98%);
      }
    }
  }
}

.LeftNavListWrapper-shrink .MuiList-root.main-menu-left .MuiListItemButton-root .MuiListItemIcon-root{
  min-width: 25px !important;
}
.main-menu-left,
.MuiList-root.main-menu-left {
  .MuiListItemButton-root {
    .MuiListItemIcon-root {
      min-width: 30px;
      color: $leftNavText;
      padding-left: 0px;
      svg {
        color: $leftNavText;
      }
    }
    .MuiListItemText-root {
      font-family: $robotoFont !important;
      font-size: 1rem;
      color: $leftNavText;
      font-weight: 600;
      width: auto;
      .MuiTypography-root {
        font-family: $robotoFont !important;
        font-weight: 600;
        font-size: 0.875rem;
        padding-left: 0px;
      }
    }

    &.Mui-selected {
      background-color: transparent;

      .MuiListItemIcon-root {
        svg,
        path {
          color: $whiteColor;
          fill: $whiteColor;
        }

        .MuiSvgIcon-root.icon-color-default,
        .MuiSvgIcon-root.icon-color-default svg {
          fill: $whiteColor;
        }
      }
      .MuiListItemText-root {
        color: $whiteColor;
      }
    }
  }
}

/* ---- Jobs page style ---- */

.jobs-search-filter-checkbox {
  .MuiFormControlLabel-root {
    .MuiFormControlLabel-label {
      font-size: 0.75rem;
    }
    .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
}

.apexcharts-legend-series {
  pointer-events: none !important;
}

.MuiAvatar-root.avarar-style {
  background-color: transparent;
  height: 33px;
  width: 33px;
  border-radius: 100%;
}

.MuiPaper-root.jobs-search-box {
  background: #f4f5f8;
  padding-left: 20px;

  .MuiInputBase-root {
    font-family: $abelFont;
  }
  .MuiButtonBase-root.search-icon:hover {
    svg,
    path {
      fill: #fff;
    }
  }
}
::placeholder {
  color: #b3b3b3;
 
}
.MuiPaper-root.blueheading {
  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
        color: $primaryColor;
        }

      }
    }
  }
}

.largesvg {
  transform: scale(1.8);
}
.MuiPaper-root.jobs-table {
  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-family: $robotoFont;
          font-weight: 500;
          color: #353535 ;
          font-size: 16px;

          .MuiTypography-root {
            font-family: $abelFont;
          }
          img {
            top: 8px;
            position: relative;
          }
        }
      }
    }

    .jobs-button-nonAdmin
    {
      margin-top: 0px !important;
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        position: relative;
        padding: 10px 0;

        td,
        th {
          z-index: 2;
          position: relative;
          vertical-align: top;
          text-decoration: none;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          padding-bottom: 10px;

          .jobs-button {
            text-transform: none;
            font-size: 0.875rem;
            border-radius: $border-radius;
            padding: 2px 10px;
            margin-top: 10px;

            &.jobdetail {
              margin-right: 5px;
            }

            @media  screen and (max-width: 667px) {
              font-size: 0.8rem !important;
            }
            
            @media  screen and (min-width: 668px) and (max-width: 1210px) {
              font-size: 1.0rem !important;
            }
          }

          * {
            font-family: $abelFont;
            text-decoration: none;
          }

          .price {
            font-weight: 600;
            line-height: 24px;
            font-size: 18px;
          }

          .view-submission-btn {
            position: absolute;
            left: 10px;
            bottom: 10px;

            .MuiButtonBase-root {
              font-size: 1rem;

              &:hover {
                background-color: transparent !important;
              }
            }
          }

          .submission-progress-section {
            position: absolute;
            right: 10px;
            bottom: 20px;
          }
        }
        &:hover {
          background-color: #e2f0ff !important;

          .MuiButton-textSecondary{
            svg{
              fill: #000 !important;
              path{
                fill: #000 !important;
              }
            }
          }
          &::after {
            background-color: $primaryColor;
          }
          .MuiTableCell-root,
          .MuiTypography-root,
          a,
          .MuiButtonBase-root svg,
          .MuiButtonBase-root {
            font-size: 14px;
          }
          .featurejobIcon{
           
            /* or to blue */
            filter: brightness(0) invert(1);
              
           
          }
          .MuiButton-root.jobs-button,
          .MuiButton-root.jobs-button:hover {
            background-color: #fff !important;
            color: $primaryColor !important;

            &.MuiButton-containedSecondary {
              color: $greyColor !important;
            }
          }
          .MuiButton-root.jobs-button:hover {
            background-color: rgba(255, 255, 255, 0.8) !important;
          }
        }

        .MuiPaper-root.client-location-card {
          background-color: transparent;

          .MuiAvatar-root {
            width: 33px;
            height: 33px;
            border-radius: 100%;
            overflow: hidden;
          }

          .client-location-text {
            font-weight: '500';
            line-height: 16px;
            padding-top: 5px;
          }
        }
        .MuiTypography-root.job-title {
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 0;
        }
        &.subRow {
          td {
            padding-bottom: 20px;
          }
        }
      }  
    }

    .MuiTableCell-root {
      padding: 10px;

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
}


.MuiPaper-root.submission-table {
  .MuiTable-root {
    .MuiTableHead-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          font-family: $abelFont;
          font-weight: 600;
          color: $primaryColor;
          font-size: 0.875rem;

          .MuiTypography-root {
            font-family: $abelFont;
          }
          img {
            top: 8px;
            position: relative;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        position: relative;
        padding: 10px 0;

        td,
        th {
          z-index: 2;
          position: relative;
          vertical-align: top;
          text-decoration: none;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;

          .jobs-button {
            text-transform: none;
            font-size: 1.2rem;
            border-radius: $border-radius;
            padding: 2px 10px;
            margin-top: 10px;

            &.jobdetail {
              margin-right: 5px;
            }
          }

          * {
            font-family: $abelFont;
            text-decoration: none;
          }

          .price {
            font-weight: 600;
            line-height: 24px;
            font-size: 18px;
          }

          .view-submission-btn {
            position: absolute;
            left: 10px;
            bottom: 10px;

            .MuiButtonBase-root {
              font-size: 1rem;

              &:hover {
                background-color: transparent !important;
              }
            }
          }

          .submission-progress-section {
            position: absolute;
            right: 10px;
            bottom: 20px;
          }
        }
        

        .MuiPaper-root.client-location-card {
          background-color: transparent;

          .MuiAvatar-root {
            width: 33px;
            height: 33px;
            border-radius: 100%;
            overflow: hidden;
          }

          .client-location-text {
            font-weight: '500';
            line-height: 16px;
            padding-top: 5px;
          }
        }
        .MuiTypography-root.job-title {
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 0;
        }
        &.subRow {
          td {
            padding-bottom: 20px;
          }
        }
      }
    }

    .MuiTableCell-root {
      padding: 10px;

      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 15px;
      }
    }
  }
}

/* Global App Table CSS start*/

.AppTableList{
  background-color: $pageBackgroundColor !important;
  border-collapse: separate !important;
  border-spacing: 0 4px !important;
  border-top: 1px solid $borderColor !important;
}

.AppTableList-table-row{
  background-color: #fff !important;
}

.AppTableList-table-row:hover {
  background-color: $hoverRowColor !important;
}

.AppTableList th, .AppTableList td {
  padding: 8px !important;
}

.AppTableListHeader th{
  background: $pageBackgroundColor !important;
}

.AppTableList td{
  border-bottom: none !important;
  font-size: 0.875rem;
}
/* Global App Table CSS End*/

/* Jobs details page */

.MuiFormControl-root.payrate-input {
  margin-left: 0;
  width: 75px;
}

.activity-chips {
  .MuiChip-root {
    border: none;
    font-family: $abelFont;
    font-size: 1rem;
    line-height: 30px;
    justify-content: flex-start;
    & > svg {
      height: 30px;
      font-size: 1.2rem;
    }
  }
}

.withRecruiterInfo{
  padding-bottom: 15px;
}

.MuiBox-root.jobsRightSidebar {
  .MuiListItem-root {
    font-size: 1rem;
    padding-left: 0;
  }

  .jobsRightButtons {
    width: 100%;

    .MuiButton-root {
      width: 100%;
      margin-bottom: 10px;

     

      &:hover svg {
        fill: #fff;
      }
    }
  }
}

.MuiBox-root.scorebyskills-chart {
  svg,
  foreignObject {
    .apexcharts-legend {
      display: flex;
      justify-content: space-between;
      @include padding-x(0);

      .apexcharts-legend-marker {
        width: 7px !important;
        height: 7px !important;
      }
    }
  }
}

.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* Home page */

.MuiContainer-root.home-page-search {
  @include padding-y(100px);
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  & > .MuiBox-root {
    width: 600px;
    align-self: center;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    & > span {
      padding: 0 20px;
      line-height: 40px;

      @media screen and (max-width: 480px) {
        padding: 0 10px;
      }

      svg {
        position: relative;
        margin-right: 10px;
        top: 6px;
        font-size: 1.5rem;
        color: #878787;

        @media screen and (max-width: 480px) {
          font-size: 1.2rem;
          top: 4px;
        }
      }
      .MuiTypography-root {
        font-size: 1.2rem;
        color: #878787;

        @media screen and (max-width: 480px) {
          font-size: 0.823rem;
        }
      }
    }

    &.home-search-box {
      padding: 20px;

      .MuiInput-root {
        position: relative;

        input {
          background: #fff;
          box-shadow: 0px 1px 22px 1px rgba(69, 64, 219, 0.09);
          border-radius: $border-radius;
          outline: none;
          border: none;
          padding: 15px 20px;
        }

        &::before,
        &::after {
          display: none;
        }

        .MuiInputAdornment-root {
          position: absolute;
          right: 20px;
          top: 7px;
        }
      }
    }
  }
}

.home-recruiter {
  .home-recruiter-card {
    padding-top: 30px;

    .MuiPaper-root {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: $border-radius;
      height: 100px;
      position: relative;
      transition: transform 0.3s;

      &:hover {
        background-color: $primaryColor;
        color: #fff;
        transform: scale(1.06);
      }

      .home-recruiter-card-box {
        justify-content: flex-start;
        padding: 12px 5px 15px 15px;
        text-align: left;

        .home-recruiter-card-icon {
          background-color: transparent;
          height: 50px;
          width: 50px;
          border-radius: 0;

          svg {
            font-size: 2.3rem;
          }

          &.cloud,
          &.fullstack,
          &.data {
            svg {
              font-size: 2.7rem;
            }
          }
        }
        .home-recruiter-card-text {
          padding-left: 10px;

          .MuiTypography-root.title {
            font-size: 1.375rem;
            line-height: 1.5625rem;
            height: 52px;
            width: 100%;
            overflow: hidden;
            @media screen and (max-width: 480px) {
              font-size:  1.3rem;
              
            }
          }
          .subtitle {
            font-size: 1.125rem;
            @media screen and (max-width: 480px) {
              font-size: 1.2rem;
              
            }
          }
        }
      }
    }
  }
}

.home-feature-jobs {
  .home-feature-jobs-card {
    padding: 0;
    .MuiPaper-root {
      @include card-shadow-radius();
      text-align: left;
      transition: transform 0.2s;
      &:hover {
        transform: scale(1.06);
      }
      .price {
        font-weight: 600;
        line-height: 24px;
        font-size: 17px;
      }

      .MuiTypography-body2 {
        font-size: 0.75rem;
        line-height: 0.875rem;
      }
    }
  }
}

.submissionListSearchBar{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.home-connect-submit-box {
  background-color: $primaryColor;
  border-radius: $border-radius;
  background-repeat: no-repeat;
  background-position: 0 0;
  min-height: 550px;

  @media screen and (max-width: $medium) {
    background-size: 400px 400px;
  }

  @media screen and (max-width: 600px) {
    background-image: none;
    padding: 20px;
  }

  @media screen and (max-width: 480px) {
    background-image: none;
    padding: 20px;
  }

  .connect-text {
    padding-top: 100px;
    @media screen and (max-width: 480px) {
      padding-top: 0px;
    }
  }

  button {
    background-color: #fff;
    color: $primaryColor;
    text-transform: none;
    font-weight: 600;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8) !important;
    }
  }
}

.home-subscribe {
  background-color: $secondaryColor;
  margin-top: 130px;
  height: 120px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 230px;
  }

  .home-subscribe-box {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: $border-radius;
    height: 150px;
    position: relative;
    top: -80px;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      height: auto;
      padding-bottom: 15px;
    }

    .subscribe-text {
      font-size: 2rem;
      color: #000;
      padding-left: 30px;

      @media screen and (max-width: 480px) {
        padding-left: 20px;
      }
    }

    .subscribe-form {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      margin-top: 15px;

      @media screen and (max-width: 480px) {
        margin-top: 0px;
      }
    }
  }
}

.MuiTabs-root.manage-accounts-tabs {
  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      justify-content: flex-start;
      padding-left: 16px 8px;
      min-height: 45px;
      font-weight: 400;
      text-transform: none;
      font-family: $robotoFont;
      font-size: 1rem;

      svg {
        color: $greyColor;
        fill: $greyColor;
        font-size: 1.1rem;
        margin-right: 10px;
        path {
          fill: $greyColor;
        }
      }

      &:hover,
      &.Mui-selected {
        background-color: #fff !important;
        color: $light_dark_black;
        font-weight: 600;

        svg {
          color: $light_dark_black;
          fill: $light_dark_black;
          path {
            fill: $light_dark_black;
          }
        }
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}

.score-wrapper{
  margin-bottom: 50px;
}

button.MuiButton-outlinedPrimary {
  background-color: $secondaryColor;
  color: #4540DB;
}
td button.MuiButton-outlinedPrimary {
  color: #4540DB;
}
button.MuiButton-outlinedPrimary:hover {
  background-color: transparent;
  color: $secondaryColor  !important;
}
.recruiter-img{
  width: '100'; height: '100'; border-radius: '50px'; display: 'block'; margin: '0 auto' 
}

.ml-15{
  margin-left: 10px;
}

.reach-us-text{
  padding-bottom:0px;
  padding-left: 10px;
  @media screen and (max-width: 900px){
    padding-top: 10px;
    }
}

.quick-link-text{
  padding-left: 45px;
}

.Item-Gd{
  padding-bottom: 10px !important;
  margin-top: 5px;
}

.twitterimg, .smsIcon{
  height: 26px;
}

.ht-26{
  height: 24px;
}


.footer-text{
  font-size: 12px;
  font-weight: 400;
  vertical-align: super;
  color: #0E0E2C;
  text-decoration:none;
}

.footer-left-link{
  text-decoration: none;
  color:#0e0e2ccc;
}

.footer-upper-link{
font-size: 12px;
color: #0E0E2C;
vertical-align: sub;
font-family: $robotoFont;
}

.footer-lower-link{
  font-size: 12px;
  color: #0E0E2C;
  vertical-align: sub;
  font-family: $robotoFont;
}

.foot-header{
  font-size: 1rem !important;
  font-family: $robotoFont !important;
}

.q-link{
  color: #0E0E2CCC;
}

.cpyright-text{
   color:#5D616F;
   padding-top:10px;
   padding-bottom:0px;
   background-color: #E6E9FB;
   font-size:12px !important;
   padding-left: 0px;
    font-family: $robotoFont !important;
}

.smsImageIcon{
  position: absolute;
  width: 30px;
  top: 2px;
}

.smsIconText{
  margin-left: 40px;
  margin-top: 3px;
}

span.uploadPhotoText {
  white-space: nowrap;
}


.MuiChip-root {
  border-radius: $border-radius !important;
  height: auto;
  min-height: 28px !important;
  font-size: 14px !important;
}

.muiLabelCustom{
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: $robotoFont;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
}

.max-skills-text{
  transform-origin: top right !important;
}

.rightLabelText {
  margin-right: -24px;
}

.fileUploadInfotext{
  font-size: 12px !important;
}
.V2themefont{
  font-size: 14px !important;
}

.MuiFormLabel-asterisk,
.required-asterisk {
  color: #d2010d;
}

.typography-with-right-text {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .max-skills-text {
    color: $normalFontColor;
    font-size: 14px;
    padding-top: 5px;
  }
  .edit-icon {
    .MuiButtonBase-root {
      svg {
        font-size: 1rem;
      }
      &:hover {
        svg,
        svg path {
          color: #fff;
          fill: #fff;
        }
      }
    }
  }
}
.user-profile-form {
  .checkbox-sml.MuiFormControlLabel-root {
    .MuiCheckbox-root {
      svg {
        position: relative;
        top: 0px;
      }
    }
  }

  .MuiAutocomplete-root.skills-chip {
    background-color: $secondaryColor;
    padding: 10px;
    border-radius: $border-radius;

    .MuiInput-root {
      .MuiChip-root {
        width: 120px;
        border-radius: $border-radius;
        font-size: 1.2rem;
      }
    }
  }
}

.uploadPhotoClose{
  padding: 5px;
}
.upload-Photo-dialog {
  .MuiDialogContent-root {
    padding: 0;
    text-align: center;

    .MuiDialogTitle-root {
      background-color: rgb(206 213 251);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px auto;

      svg {
        font-size: 5rem;
      }
    }

    .dialog-close-btn {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .MuiDialogActions-root {
    @include padding-x(50px);

    .MuiButton-root {
      text-transform: none;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
        svg,
        svg path {
          color: #fff;
          fill: #fff;
        }
      }
    }
  }
}

.billing-form {
  .checkbox-sml {
    .MuiButtonBase-root {
      position: relative;
      top: -4px;
    }
  }
}

.others-billing {
  background: $light-blue-color;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 16px;

  & > .MuiTypography-root {
    text-align: justify;
    font-size: 0.875rem;
    @include padding-x(16px);
  }
}

.w20{
  width: 20px;
}

.rec-email-text-sp{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: calc(100% - 25px);
}

.autocomplete-formControl-jr label + .MuiInput-formControl {
  margin-top: 0px;
}

.Other-doc-viewMode-heading{
  padding-bottom: 10px;
}

.ackn-skill-heading{
  padding: 0 0 0 8px !important;
}

.ackn-skill-input-list{
  padding: 0 8px !important;
}

.akcn-payrate-val{
  padding:5px 0 0 0;
}

.rec-email-text-super{
  vertical-align: middle;
}

.res-textname{
  vertical-align: revert;
}

.res-dwnlod-icn{
  vertical-align: text-bottom;
}

.basic-info-verify-icon{
  margin: 25px 0 0 10px;
}

.rec-email-text{
  color: #000;
}

.us-billing-checkbox {
  .MuiFormControlLabel-root {
    align-items: start;
    @include margin-y(7px);

    .MuiButtonBase-root {
      position: relative;
      top: -11px;
    }
    .MuiTypography-root {
      font-size: 0.875rem;
      line-height: 1.1rem;
    }
  }
}

.job-details-info-tbl {
  @include margin-y(15px);

  .MuiPaper-root {
    background-color: transparent;
    text-align: left;
  }

  .primary-color-text {
    font-size: 1rem;
  }
}

#success-dialog-description {

  .success-txt {
    font-size: 0.875rem;
    color: $normalFontColor;
    line-height: 1.25rem;
  }
  .sucess-popup-action {
    padding-top: 10px;
    .MuiButton-root {
      margin: 0 5px;
    }
  }
}

.btn-x-padding,
.MuiButtonBase-root.btn-x-padding {
  @include padding-x(50px);
}

/* Submit Resume page */

.compensation-calculator-box {
  background-color: $light-blue-color;
  margin-top: 30px;
  padding: 15px;

  .MuiTypography-caption {
    line-height: 17px;
  }

  .MuiTypography-body1 {
    font-size: 18px;
  }

  .compensation-calculator-table {
    .MuiPaper-root {
      font-size: 17px;
    }
  }

  .MuiPaper-root {
    .MuiGrid-root {
      .MuiGrid-item {
        padding-left: 0;

        .MuiPaper-root:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
.candidate-employment-list {
  .MuiGrid-root {
    .MuiPaper-root {
      padding-bottom: 0;
      padding-left: 0;
    }
  }
}

.feedback-box {
  position: relative;

  &::before {
    content: "";
    width: 1px;
    height: calc(100% - 28px);
    background-color: $greyColor;
    position: absolute;
    top: 34px;
    left: 15px;
    display: inline-block;
  }
}

img.edit-rec-verifyIcon {
  margin: 10px 0 -10px 10px;
}

.about-card-icons{
  background: rgba(79, 109, 245, 0.06);
  box-shadow: 0px 4.141px 4.141px rgba(0, 0, 0, 0.25);
  border-radius: 20.705px;
  width: 180px;
  height: 180px;

  span {
      background-color: #fff;
      width: 130px;
      height: 130px;
      border-radius: 100%;
      display: inline-block;
      margin-top: 20px;

      img {
          width: 70px;
          margin-top: 20px;
      }
  }
}

.theme-bg-custom-menu .MuiMenu-list{
  background-color: rgb(230, 233, 251) !important;
}
.gotoTop {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: red; /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 15px; /* Some padding */
  border-radius: 10px; /* Rounded corners */
  font-size: 18px; /* Increase font size */
}

.SendMessageButton {
  background-color: #fff;
  color: $primaryColor;
  text-transform: none !important;
  font-weight: 600;
}

.viewMode.react-tel-input :disabled {
  border-bottom-style: dotted !important;
  border-bottom-color:rgba(0, 0, 0, 0.42) !important;
}

label.relocate-ackn {
  margin-top: -10px;
}

.edit-icon-btn {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.score-resume-btn-ackn {
  margin-right: 15px !important;
}

.fontWt700{
  font-size: 12px !important;
  font-weight: 400;
}

.resume-gridItem {
  min-width: 140px;
  padding-top: 12px;
}

.emailNotEditable{
  border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

.acknVerifyEmailStick{
margin:0px 0 -7px 5px
}

.ackn-email-fieldControl {
  padding-top: 16px !important;
}

.ackn-form input.MuiInputBase-input.MuiInput-input.Mui-disabled.Mui-disabled {
  color: #000000 !important;
}

.ackn-form .viewMode.react-tel-input :disabled{
  color: #000000 !important;
}
.client-react-tel-input{
  margin-top: 3px !important;
}

.ackn-file-uploader {
  padding: 0 !important;
}

.column-ftc{
  margin-top: -7px !important;
}

.ackn-phone-error{
  margin-top: -10px !important;
  margin-left: 5px;
}

.OtherDocSec{
  margin-top: 30px;
}

.OtherDocSec .docInnerContainer{
  margin-bottom: -25px;
}

.ackn-heading{
  font-size: 1.5rem !important;
}

.cand-container label + .MuiInput-formControl{
  margin-top: 6px !important;
}

.iconEditbtn{
  padding: 0px;
}

.rec-pb{
  padding-bottom: 12px !important;
}

span.rec-comment-date {
  color: #8a8d8d;
}
span.cand-comment-date {
  color: #8a8d8d;
}

.ackn-comment{
  color:#000 !important;
}

.iconEditbtn:hover{
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.tabs-panels-header{
  .MuiBox-root{
    padding: 0 !important;
  }
}

.contractTab .custom-header-collapse .MuiAccordionSummary-content{
margin: 0;
padding: 0px 10px 8px !important
}

.contractTab .custom-header-collapse > .MuiButtonBase-root{
padding: 15px 10px 0px !important;
}

.contact-pageWrapper {
  padding: 25px 0 0 12px;
}

.custom-header-collapse{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  margin-bottom: 20px;
  
  .MuiAccordionDetails-root{
    border: none;
  }
  .MuiAccordionSummary-content{
    font-size: 20px !important;
  }
  > .MuiButtonBase-root{
    background-color: transparent !important;
    color: #4540DB !important;
    padding: 15px 10px 20px !important;
    svg{
      color: #4540DB !important;
    }
  }
}
.custom-tabs-wrapper{
  .Mui-selected{
    &:hover{
      color: white !important;
    }
  }
    .MuiTabs-indicator{
      height:4px;
    }
}
.custom-table-compnent {
  max-width: 100%;
  box-shadow: none !important;
  th{
    font-size: 16px;
    color: #4540DB;
    padding: 8px 8px;
  }
  td{
    color: #000000;
    font-size: 14px !important;
  }
}
.custom-table-compnent-new {
  max-width: 100%;
  box-shadow: none !important;
  th{
    font-size: 16px;
    color:black;
    padding: 8px 8px;
  }
  td{
    color: #000000;
    font-size: 14px;
  }
}

.custom-menu-bar{
  .MuiPaper-root {
    &.MuiPaper-elevation{
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12) !important;
    }
  }
  .MuiList-root{
    background-color: white !important;
  }
}

.blue-magnifying-img{
  width: 400px;
  height: 406px;
  @media screen and (max-width: $small) {
    width: 250px;
    height: 250px;
  }

  @media screen and (max-width: 400px) {
    width: 220px;
    height: 220px;
  }
}

.contactus-grid{
  margin-left: 25%;
  @media screen and (max-width: $small) {
    margin-left: 0%;
  }
  @media screen and (max-width: $medium) {
    margin-left: 0%;
  }

  @media screen and (max-width: 400px) {
    margin-left: 0%;
  }
}
a {
  color: $primaryColor;
}

.arrowright.featurejobicon{
  font-size: 1.5rem;
  z-index: 3;
  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
}

.price.featuredjob{
  @media screen and (max-width: 480px) {
    font-size:13px !important;
  }
}

.error-msg-for-comment{
  font-family:$robotoFont !important;
  font-size: 0.875rem;
  color:#f44336;
  padding-top: 7px;
}

.primary-color{
  color: #4540DB;
}

.react-multi-carousel-track{

  margin-bottom: 20px !important;

}

.disabled-comp{
  color: rgba(0, 0, 0, 0.38) !important;
}

.pl-8-imp{
padding-left:8px !important;
}

.helpInfoIcon{
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 9;
}

.closeIconOnYutube {
  position: absolute;
  margin: 5px auto;
  left: 662px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 9;
}

.youtubeCloseIconFab{
  background-color: rgba(0, 0, 0, 0.00);
  width: 35px;
  height: 30px;
  line-height: 30px;
  box-shadow: none;
}

.verify-email-item-box{
  padding: 0 auto !important;
}

.cls-p-15{
  padding: 15px;
}

.cls-ptb-15{
  padding: 15px 0;
}

.cls-ptb-20{
  padding: 20px 0;
}
.login-banner-text{
  @media screen and (max-width: 480px) {
    padding-bottom: 0 !important;
  }
}

.signUpComplete-phoneInput .MuiFormControl-root {
  width: 67% !important;

  @include max_width_devices($mui_sm_breakpoint){
    width: 98% !important;
  }

  @include max_width_devices($custom_breakpoint_480){
    width: 67% !important;
  }
}

.PageTopMargin{
  margin-top: 20px;
}

.NotificationEmailTextField{
  min-width: 300px;
}

.NotiBreadCont{
  padding-left: 0px !important;
  margin-left: 0px !important;
}


.Update-ele{
  margin-top: 15px;
  margin-bottom: 15px;
}

.marginTBL200510{
  margin: 20px 0 5px 10px;
}

.marginLB1005{
  margin: 0 0 5px 10px;
}

.d-inline{
  display: inline-block;
}

.phn {
  display: inline-flex;
  align-items: flex-end;
}

.cand-comment-heading{
    display: block;
    padding: 10px 15px 10px 8px;
}

.ackn-candidate-feedback {
  padding: 0 0 0 13px;
}

.commentFeedback-ackn{
  font-size: 1.125rem !important;
}

.cand-commentRows {
  padding: 0 0 10px 5px;
}

.candidateCommentList {
  margin: -6px 0 0 0;
}

.ackn-addComment{
  padding: 5px;
}

.custom-flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-column{
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
  > div {
    &:nth-child(1){
      @media screen and (max-width: 480px) {
        margin-bottom: 10px;
        text-align: left;
      }
    }
  }
  > span{
    @media screen and (max-width: 480px) {
      margin-top: 10px;
      text-align: left;
    }
  }
}
.custom-banner-heaeder-padding{
  padding: 40px 30px;
  @media screen and (max-width: 480px) {
    padding: 40px 30px 30px;
  }
}
.custom-email-wrapper{
  display: flex;
  align-items: flex-end;
  padding: 0 0 30px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 15px 0;
  }
  .MuiFormControl-root{
    @media screen and (max-width: 480px) {
    width: 100%;
    }
  }
}
.email-text-wrap{
  @media screen and (max-width: 480px) {
    max-width: 100% !important;
    }
}

.copyright-column{
  text-align: center;
}

.sms_number{
  @media screen and (max-width: 1388px) {
    vertical-align: super
    }
}

.Footer-box {
  display: flex;
  align-items:center;
  position: relative;
}

a.footer-link {
  text-decoration: none;
  font-size: 12px;
  color: #0E0E2C;
}

.static-footer-wrapper{
    background-color: #E6E9FB;
    position : relative;
    max-width: 100%;
    display: flex;
    padding: 25px 15px 25px 70px;  

    @include max_width_devices($mui_lg_breakpoint){
      padding: 25px 15px 45px 70px; 
    }

    @include max_width_devices($mui_md_breakpoint){
      padding: 25px 15px 25px 25px;  
    }

    @include max_width_devices($mui_sm_breakpoint){
      padding: 15px 0px 15px 0px;  
    }
    
}

.custom-charts{
  .apexcharts-canvas{
    margin: 0 auto !important;
  }
}
.file-upload-wrapper{
  max-width: 340px;
  min-width: 340px;
  width: 100%;
  height: 145px;
  background: #F8F8FF;
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  text-align: center;
  padding: 0px;
  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  cursor: pointer;

  .upload-wrapper{
    position: relative;
   
    margin-left: 5px;
    input{
      opacity: 0;
      width: 50px;
      position: relative;
      z-index: 1;
    }
    label{
      position: absolute;
      left: 0;
      top: 0;
      text-decoration: underline;
      color: #483EA8;
    }
    
  }
  .custom-flex-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    margin-top: 25px;
  }
  .small-text{
    font-size: 10px;
    color:#676767;
    margin-top: 10px;
  }
}

.custom-width-200{
  min-width: 200px;
}
.upload-wrapper-text{
  width: 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  padding: 6px 10px;
  margin-top: 10px;
  position: relative;
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0 !important;
    border-radius: 50%;
    &:hover{
      background: transparent !important;
    }
  }
  &.success-upload{
    border: 1px solid #11AF22;
    button{
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  progress{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background:transparent ;
    &::-webkit-progress-value {
      background: #483EA8;
      border-radius: 8px;
    }
    
    &::-webkit-progress-bar {
      background: transparent;
    }
  }
}

span.ackn-rec-pri0number {
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: calc(100% - 25px);
}

.alertSwitchFormControl{
margin-right: 5px !important;
}
.custom-dialogue-wrapper-lg{
  .MuiDialog-paper{
    background: #E6E9FB;
    min-width: 900px;
  }
}
.custom-dialogue-wrapper-sm{
  .MuiDialog-paper{
    background: #E6E9FB;
    width: 500px;
  }
  
}
.custom-jobalert-dialogue-wrapper-sm{
  .MuiDialog-paper{
    width: 370px;
  }
  
}
.pointer{
  cursor: pointer;
}

.inp-color{
  color: #000;
}

.hide-work-auth{
  display: none;
}
.main-wrapper-uploader{
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  
  .title-text{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .upload-wrapper-text{
    width: auto;
    margin: 10px;
    margin-left: 0 !important;
    button{
      margin-left: 10px;
    }
  }
}
.uploading-rapper-main{
  max-height: 198px;
  overflow-y: auto;
}

.ContractDetailsPanel .MuiGrid-item{
  margin-bottom: 7px !important;
}

.ContractDetailsPanel {
  padding: 30px !important;
}

.bg-none{
  &:hover{
    background: none !important;
  }
}
.input-wrapper-main{
  position: relative;
  margin-bottom: 10px !important;
  .input-wrapper-inner{
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 59px;
    height:59px;
  }
}
.oneline{
  display: flex;
  flex-direction: row;
}
.oneline1{
  display: flex;
  flex-direction: row;
  margin-left: 17px;
}
.oneline2{
  margin-left: 17px!important;
}
.emp-add-width{
  width: 28%!important;
}
.phn-cntry-cstm-copy{
  margin-top: -6px!important;
}
.emp-phn-margn{
  margin-top: -3px!important;
}
.sub-res-add{
  min-width: 100%;
}
.can-emp-pading{
padding: 0!important;
}
.emp-name{
  width: 13%!important;
}
.emp-contact-email-width{
 width: 33.5%!important;
}
.emp-contact-email-width-candDet{
  width: 27%!important;
 }

 .phoneInputDisabled .form-control{
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38) !important;
 }
 .phoneInputDisabledborder .form-control{
  border: none !important;
 }
.emp-contact-email{
  margin-left: 15px !important;
  width: 285px !important;
  margin-right: 0px !important;
}

.emp-details-phone{
  margin-left: 15px !important;
  width: 275px !important;
}
.form-fur-child-sub{
  margin-bottom: 20px!important;
  margin-top: 5px;
}

.custom-flex-dis{
  display: flex;
 
}
.custom-form-set{
  width: 90%!important;
}
.custom-grid-set{
  flex-basis: 21.6%!important;
  max-width: 22.666667%!important;
}
.custom-grid-set1{
 
  max-width: 90%!important;
}

.custom-can-email{
flex-basis: 41%!important;
}
.custom-pading-right{
  padding-right: 15px;
}
.custom-text-upload{
  margin: 7px;
  margin-bottom: 25px!important;
  
}



.custom-btns-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.btn-space{
  margin-right:10px!important;
  padding: 0 16px;
}

.btn-space1{
  margin: 0 35px !important;

}
.btn-cclose button img{
  padding: 0 5px 0 0;
  }

  
    
      .custm-apprvd{
        display: flex!important;
        align-items: center;
        flex-wrap: nowrap;
        padding: 47px 0 46px 0!important;
      
      }

  .MuiTableBody-root.css-apqrd9-MuiTableBody-root tr{
    border-bottom: solid 1px rgba(224, 224, 224, 1);
  }
  .MuiTableRow-root.css-3edeld-MuiTableRow-root .css-109f62o-MuiTableCell-root {
border-bottom:0!important;
  } 
 
.custom-fontsizes{
  font-size: 16px!important;
    color: #4540DB!important;
    
} 

.cust-fontsizes.custom-fontsizes{
  font-size: 12px!important;
}
.leftnav-active{
  background-color:#303cad !important;
}
.header-active{
  color: blue!important;
}
.header-not-active{
  color: #000!important;
}
.custom-table-box{
  margin-left: 2px!important;
  max-width: calc(100% - 32px)!important;
}
.css-1hbdv7e-MuiGrid-root{
  max-width: calc(30% + 6px)!important;
}
.css-f5dc2o-MuiGrid-root {
  max-width: calc(70% + 6px)!important;
}
.custom-btn-space div{
  padding: 5px 10px!important;
margin: 0 10px!important;

}
.custom-btn-space div:nth-child(2){
  display: none!important;
}

.custom-btn-space{
  justify-content: flex-end !important;
  max-width: calc(66% - 8px) !important;
  padding: 0;
  margin: 0!important;
  height: 55px;
  padding-right: 5px;
}
.cust-btn-add-Recruiter{
  margin: 0 25px 0 15px!important;
  padding: 5px 10px!important;
  }

.css-vpap0f-MuiButton-startIcon>*:nth-of-type(1) {
  width: 20px!important;
}
.closeIcon{
  width: 18px!important;
}
.custom-arrws{
  position: relative!important;
  top: 2px !important;
  width: 18px!important;
  
}
.custom-arrw-btns{
 display: flex;
 align-items: center;
 flex-direction: row;
 cursor: pointer;
}

.minWidthROw{
  min-width: 80px!important;
}
.css-dm3gzn-MuiButtonBase-root-MuiButton-root{
  margin: 0 10px;
  }

// candidate acknowledment page css start here

.ackn-score-meter{
font-size: 0.7rem !important;
}
// candiate acknowledment page css end here
.custom-resume-check{
  max-width: 10.666667%!important;
  padding: 3px;
}


.custom-resume div input, .custom-resume .css-1d0nri2-MuiSvgIcon-root{
  padding: 5px;
}

// compensation css start

.All-Compensation-header-row {
  border-bottom: 1px solid $primaryColor;
  border-top: 1px solid $primaryColor;
}

// compensation css end
// recruiters page css
.custom-fontsize-status{
  font-size: 12px!important;
}

.css-sa6qj6-MuiGrid-root {
width: 100%!important;
}
.disabled-div{
  pointer-events: none;
  opacity: 0.4;
} 
.css-vqfkof-MuiPaper-root{
  width: 90% !important;
  margin-right: 10px;
}

.custom-payrate-font{
  span{
    font-size: 14px!important;
    font-weight: 400!important;
  }
}

// Verify,Edit,Verifyied History Recruiter New design CSS start here

.HeadingDevider{
  background: $primaryColor;
}
.feedbackcomment-downArrow svg{
  vertical-align: middle;
}
.verify-rec-form-heading{
  font-size: 14px!important;
  color: $primaryColor;
}

.verify-rec-form-value{
  font-size: 16px !important;
  color: $normalFontColor;
}

.verify-rec-form-value .MuiChip-label{
  font-size: 16px !important;
}

.fontsize-verify{
  font-size: 14px!important;
}

.manage-recruiter-heading{
  padding-top: 0;
  font-size: 1.5rem !important;
}

.AdminListSearchbarWrapper{
  display: inline-block;
}


.admin-feedbckComment-heading{
  font-size: 1rem;
}

.adminComment-heading{
margin-left: 45px !important;
font-size: 1rem;
}

.admin-feedbckComment-heading1{
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.adminComment-heading1{
  margin-left: 45px !important;
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.domainChipLabel{
    margin-right: 7px;
  margin-bottom:5px
  }


  .action-btn{

    background-color: $light-blue-bgcolor !important;
    color: $primaryColor !important;
    border: none !important;
    font-weight: 600;
  
  &:hover {
      color: #fff;
      background-color: $primaryColor !important;
    
  }
}

.verify-rec-dialog-checklistBox{
  margin:5px -15px 0px 25px !important; 
}

.ver-rec-checklistText .MuiFormControlLabel-label{
  color:$normalFontColor;
  font-size: 16px !important;
  
}

.reasonComment-heading{
color: $primaryColor;
margin-top: 10px;
}

.rec-verify-reasons-ul-list{
  padding-left: 15px;
  margin-top: 0;
}

.ver-rec-checklistText > span{
  padding: 5px;
}

.loadTextReasonPopup{
  text-align: center;
  margin-left: 20px;
}

.custom-font-weight th{
  font-size: 16px !important;
  font-weight: 400 !important;
}

.custom-flex-width-frst{
  flex-basis: 54%!important;
  max-width: 54%!important;
}


.custom-inline-flex{
margin:10px 0!important;
label{
  span{
    white-space: nowrap!important;
  }
}
}

.custom-checkbox-pading {
  padding: 5px!important;
}
.custom-flex-width{
  flex-basis: 46%!important;
  max-width: 46%!important;
}
.custom-Admin-flex-width{
  flex-basis: 37%!important;
  max-width: 37%!important;
}
.custom-starticon {
span:nth-child(1) {

margin:0!important;}
}
.custom-font-size a{
  font-size: 12px !important;
  font-weight: 400 !important;
}
.custom-font-size {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.custom-searchlabel{
  form:nth-child(1){
    padding:0!important;
  }
  flex-basis: 60% !important;

  @include max_width_devices($mui_sm_breakpoint){
    flex-basis: 100% !important;
  }
}
.custom-lableform{
  margin-left: 0 !important;
  margin-right: 0 !important;

}

.custom-clrall-setalrt{
  flex-basis: 14%!important;
  max-width: 40%!important;

  @include max_width_devices($mui_sm_breakpoint){
    max-width: 100%!important;
  }
}
.custom-heading-content{
  font-size: 14px !important;
  font-weight: 400 !important;
}

.custom-check-NotActive{
  font-size: 12px !important;
  font-weight: 400 !important;
  .MuiFormControlLabel-label {
    white-space: nowrap;
  }  
  
}
.custom--txt-wrp{ 
div:nth-child(1) {
  div{
  white-space: nowrap;}
  }
}

//Verify,Edit,Verifyied History Recruiter new desisn CSS end here

// Preview File Dialog

.preview-file-name-paper {
  @include max_width_devices($mui_sm_breakpoint)
  {
  margin: 5px 35px 5px 10px !important;
  }
}
.preview-file-name{
  word-break: break-all;
}
.preview-file-dialog {
  .MuiDialogContent-root {
    height: 70vh;
    background-color: rgb(230 233 251);
  }
  #proxy-renderer {
    overflow-y: hidden; // hides extra scroll bar
  }
}

.payment_details_w9 {
  display: inline-flex;
  flex-direction: row;
  align-items: end;
}


// contact parnter popup css start here

.contact-popup-group-heading{
  color: #000;
  font-size: 14px !important;
}
// contact parter popup css end here


.custom-hover-ructer:hover{
        td{
          div{
            button{
              background-color: #E6E9FB!important;
              color: $primaryColor!important;
              svg{
                background-color: #E6E9FB!important;
                 color:$primaryColor!important;
              }
            }
            a.cust-rec-buttons{
              background-color: #E6E9FB!important;
              color: $primaryColor!important;
            }
           
          }
          a.cust-color-black{
            
            color: white!important;
          }
        }
      }

        .custom-hover-ructer{
          td{
            padding: 8px !important;
            
             
              a.cust-color-black{
              
                color: black!important;
              }
            
          }}
.cust-fontsizes
{
  tr{
    th{
      font-weight: 400!important;
    }
  }
}

  





.file-upload-wrapper-inner{
  height: 59px;
  width: 59px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}
.custom-table-headng th {
  font-size: 16px!important;
  font-weight: 400!important;
}
.cust-table-row:hover {
  td .Custom-hover-effect{
    color: #FFFFFF!important;
  }
}
.custom-currency-box{
  flex-basis: 21.333333%!important;
  max-width: 25.333333%!important;
}

.page-access-btn{
    width: 100%;

  }

.Custom-cantxt{
  white-space: nowrap;
  margin: 8px 0!important;
}

.custom-admin-page{
  margin-left: 10px;
  padding:0!important;
  flex-grow:1;
}
.custom-pages-padding{
padding: 0!important;
}
.custom-action-border{
  border-top: 1px solid #eee3e3 !important;
}
// rec listing table

.table-pl-0{padding-left: 5px !important; min-width: unset !important;}
.custom-btn-space .clear-mr-10{margin-right: 10px;}

// upload css
.upload-col-1{max-width: 35% !important;}

// update button right
.btn-right-update{text-align: right !important;}

// max 500 char
.flex-div{display: flex; justify-content: space-between;}
.max-limit{font-size: 12px; color: #929292; display: inline-block;}
// work auth space
.Work-Auth-mt{margin-top: 0px !important;}



.custom-view-details{
  margin-top: 0px!important;
  font-size: 0.875rem!important;
  padding-top: 6px!important;
  padding-bottom: 6px!important;
}

.custom-all-pages{
  margin: 15px !important;
  padding:0!important;
  flex-grow:1;

  @include max_width_devices($mui_md_breakpoint){
    margin: 15px 15px 15px 15px!important;
  }
}


  .ackn-otherDocBox-view{
    margin-top: -40px;
  }

  .ackn-otherDocBox-edit{
    margin-top: -20px;
  }
  // candiate acknowledment page css end here
.custom-table-padding{
  tr{
 
    td{ 
     padding-top: 10px!important;
     padding-bottom: 10px!important;
      }
    }
 }
 .custom-admin-table-padding{
   tr{
  
     td{ 
      padding-top: 10px!important;
      padding-bottom: 5px!important;
       }
     }
  }

  // recruiter location width
.rec-Location-width{
  width: 100px;
}
 

// New Submission design CSS and responsive screen css start here

.submission-pageToolbarInfo{
text-align: right;
margin-right: 10px !important;

@include min_width_devices($mui_xs_breakpoint) {
  text-align: left;
}

@include min_width_devices($mui_sm_breakpoint) {
  text-align: right;
}
}

.searchText-duration-submission{

  font-size: 14px !important;
  vertical-align: super;

  @include min_width_devices($mui_xs_breakpoint) {
    display:block;
    margin-bottom:10px !important;
    min-Width:auto;
    font-size: 12px;
   }

  @include min_width_devices($mui_sm_breakpoint) {
   display:inline;
   margin-right:15px !important;
   min-Width:275px;
  }
}

.pageToolbarContainer{

  @include min_width_devices($mui_xs_breakpoint) {
    margin-top: 10px;
   }

  @include min_width_devices($mui_sm_breakpoint) {
    margin-top: 24px;
  }
}

.selectDuration-btn{
  background-color: $light-blue-color !important;

}

.stsGridColSubmission{

  @include min_width_devices($mui_xs_breakpoint) {
    padding-top: 0px !important;
   }

  @include min_width_devices($mui_sm_breakpoint) {
    padding-left: 0px !important;
  }
}

//  New Submission design CSS and responsive screen css End here
.custom-login-username{
  span{
  font-size: 0.875rem!important;
  font-weight: 600;
  }
}

.custom-resume .MuiGrid-grid-md-5{
  max-width: 55.666667%!important;
  flex-basis: 45%!important;
}

.approve-earning-table-compnent {
  max-width: 100%;
  box-shadow: none !important;
  th{
    font-size: 14px;
    color: #4540DB;
    padding: 8px 8px;
  }
  td{
    color: #000000;
    font-size: 12px;
    white-space: nowrap;
    padding: 8px 8px;
  }
}
.custom-search-alljobs{
  justify-content: flex-end;

  @include max_width_devices($mui_lg_breakpoint){
    justify-content: flex-start;
  }
}
.custom-jobwidth{
  width: 98%!important;
  margin-right: 15px;
}
.custom-Admin-jobwidth{
  width: 98%!important;
}

// all buttons color consistent 

.toolbar-btn{
  background-color: $light-blue-color!important;
}
.jobs-btn-ht{
  height: 38px!important;
}
.sub-btn-ht{
  height: 31px!important;
}
.manage-prfl-width{
  width: calc(100% - 43%)!important;
}

.login-faq-txt{
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18.75px !important;
  font-family: $robotoFont !important;
}
.MuiBox-root .login-faq-txt a{
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 18.75px !important;
  font-family: $robotoFont !important;
}

.custom-text1 {
  position: relative;
  z-index: 999;
}
.banner-image{
  position: relative;
  margin-top: -80px;

  @include max_width_devices($custom_breakpoint_480){
    width: 260px;
  }
  
}
.forgotpwd-banner-image{
  position: relative;
  margin-top: -80px;
  width: 100%;
}
.MuiBox-root .custom-wlcm-txt {
  font-family: $robotoFont !important;
  font-weight: 300;
  font-size: 32px !important;
  line-height: 37.5px;
  letter-spacing: normal !important;
}
.MuiBox-root .custom-wlcm-txt span{
  font-family: $robotoFont !important;
  font-weight: 300;
  font-size: 32px !important;
  line-height: 37.5px;
}
.MuiBox-root .custom-forgotpwd-subhead {
  font-family: $robotoFont !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-forgotpwd-submit-btn {
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  width: 126px !important;
}
.custom-signin-txt{
  font-family: $robotoFont !important;
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 30.47px !important;
  padding-top: 20px !important;
  color: rgba(53, 53, 53, 1) !important;
}
.custom-signin-btn{
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  width: 126px !important;
}
.custom-signupthnks-txt{
    font-family: $robotoFont !important;
    font-weight: 800 !important;
    font-size: 32px !important;
    line-height: 38.2px !important;
    color: rgba(69, 64, 219, 1) !important;
}
.custom-right{
  padding: 0!important;
}
.MuiContainer-root .MuiPaper-root .login-forms .MuiBox-root .MuiTypography-root .custom-signin-thnks{
  font-family: $robotoFont !important;
}
.custom-banner-txt{
  font-family: $robotoFont !important;
  font-weight: 500 !important;
  line-height: 32.81px !important;
}
.custom-banner-txt1{
  font-family: $robotoFont !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  padding-top: 26px;
}
.MuiBox-root .custom-banner-txt2 span{
  font-family: $robotoFont !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 18.75px !important;
}
.MuiList-root.login-text-padding .MuiListItem-root{
  padding-left: 0px;
}
.custom-lnk-signup{
    padding-left: 10px;
    font-family: $robotoFont !important;
    font-weight: 500 !important;
    font-size: 18px;
}
.custom-lnk-signup1{
  padding-bottom: 10px;
  font-family: $robotoFont !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: rgba(79, 79, 79, 1) !important;
}
.custom-lnk-signin {
  font-family: $robotoFont !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  padding-left: 4px;
}
.custom-alreadysignedup-text {
  color: #353535;
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

span.singnedDateText {
  text-wrap: nowrap;
}
.custom-email-signup-txt{
  cursor: pointer;
  text-align: center;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 23.44px !important;
  color: rgb(69, 64, 219) !important;
  font-family: $robotoFont !important;
}
.custom-signingup-txt{
  font-family: $robotoFont !important;
}
.custom-login-banner-txt{
  margin-top: 16px !important;
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
.custom-client-popup .MuiDialog-container .MuiPaper-root.MuiPaper-elevation{
  border-radius: 14px !important;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08) !important;
}
.custom-popup-txt{
  font-family: $robotoFont !important;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #4540DB;
  line-height: normal;
}
.custom-popup-txt1{
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal;
  padding: 30px;
}
.custom-popup-txt2{ 
  cursor: pointer;
  color: #4540DB;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
}
.custom-call-txt{
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}
.custom-call-txt-btn{
  color: #FFF !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding: 8px 24px !important;
}
.custom-submit-otp{
  border-radius: 6px !important;
  padding: 8px 24px !important;
  color: #FFF !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  margin-bottom: 15px !important;
}
.custom-success-clr{
  color: rgba(38, 194, 150, 1) !important;
}
.custom-reject-clr{
  color: rgba(248, 25, 25, 1) !important;
}
.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}
.custom-otp-input{
  margin: 15px 30px;
}


.custom-otp-gap{
  @include max_width_devices($mui_sm_breakpoint){
    gap: 8px !important;
  }
}

.custom-crose-right{
  margin-right: 0px !important;
}
.custom-adminapproved-text{
color: #353535 !important;
font-family: $robotoFont !important;
font-weight: 600 !important;
line-height: normal !important;
}

.custom-feedback-txt{
  color: #353535;
  font-family: $robotoFont !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.custom-approve-headingtxt{
  color: #26C296 !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.roboto-font{
  font-family: $robotoFont !important;
  text-transform: none !important;
}

.listing-filter li, .listing-filter li p {
  white-space: normal;
  float: left;
  width: 100%;
  height: auto;
  word-wrap: break-word;
}

.new-custom-arrws-up{
  position: relative;
  top: -6px;
  width: 18px !important;
  
}
.new-custom-arrws-down{
  position: relative;
  bottom: -5px;
  width: 18px !important;
  left: -18px;
}

.custom-admin-postdate{
color: #848484 !important;
font-family: $robotoFont !important;
font-size: 14px;
font-weight: 400 !important;
line-height: normal !important;
}

.custom-feedback-heading{
color: #F81919;
font-family: $robotoFont;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.custom-reject-headingtxt{
  color: #F81919 !important;
font-family: $robotoFont !important;
font-size: 14px !important;
font-weight: 700 !important;
line-height: normal !important;
}
.custom-email-txt{
color: #838594 !important;
font-family: $robotoFont !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: normal !important;
}
.MuiBox-root .custom-pwd-txt input{
color: #353535 !important;
font-family: $robotoFont !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: normal !important;
}
.custom-headernav-txt{
font-family: $robotoFont !important;
font-size: 16px !important;
font-weight: 400 !important;
line-height: normal !important;
}
.custom-signinbutton-txt{
font-family: $robotoFont !important;
font-size: 16px !important;
font-weight: 500;
line-height: 20px !important; /* 150% */
}

.custom-input-bckgrnd-color
 {input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #F9FAFF;
  -webkit-text-fill-color: 000;
  -webkit-box-shadow: 0 0 0px 1000px #F9FAFF inset;
  transition: background-color 5000s ease-in-out 0s;
}}

.MuiSlider-thumbColorPrimary{
  color:white !important;
  width: 25px !important;
  height: 25px !important;
}
.custom-thnxx-txt{
  font-family: $robotoFont !important;
}
.nameInput {
  text-transform: capitalize !important;
}
.client-text{
  font-family: $robotoFont !important;
}
.client-text-verify{
  color: #353535 !important;
font-family: $robotoFont !important;
font-size: 20px !important;
font-style: normal !important;
font-weight: 600 !important;
line-height: normal !important;
}

.disable-sort-icon{
  color: #C5C5DA;
}

.filter-searchbox .MuiInputBase-root{
padding-right: 0;
}
.custom-margin-call{
  margin-left: 90px !important;
}

.client-job-preview {
  .MuiDialogContent-root {
    height: 70vh;
    background-color: #FFFFFF
  }
  #proxy-renderer {
    overflow-y: hidden; // hides extra scroll bar
  }
}

/* Default styles */
.client-advanceSearchMessage {
  max-width: 600px;
  margin-bottom: 8px !important;
  font-family: $robotoFont !important;
}


.breakword{
  word-break: break-word;
}

.custom-robo-font {
  font-family: $robotoFont !important;
}

.MuiTypography-root .custom-robo-font span {
  font-family: $robotoFont !important;
}

.custom-client-rspnsv{
  @include max_width_devices($mui_sm_breakpoint){
   display: flex;
   justify-content: flex-start;
  }
}

.resetpassword-btn-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.resetpassword-btn-text {
  font-family: $robotoFont !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.resetpassword-instructions-list {
  list-style: disc !important;
  margin-bottom: 20px !important;

  .MuiListItem-root {
    display: list-item;
    list-style-position: inside;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.passwordresetsuccess-heading {
  text-align: center;
  align-items: center;
  font-family: $robotoFont !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.passwordresetsuccess-subhead {
  display: block;
  width: 100%;
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.passwordresetsuccess-btn-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordresetsuccess-btn-text {
  font-family: $robotoFont !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.forgotpasswordsuccess-heading {
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.forgotpasswordsuccess-body {
  text-align: center;
  font-family: $robotoFont !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forgotpasswordsuccess-body-email {
  font-family: $robotoFont !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.forgotpasswordsuccess-notmyemail-box {
  text-align: center !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  display: block !important;
}

.forgotpasswordsuccess-notmyemail-link {
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  text-decoration-line: underline !important;
  cursor: pointer !important;
}

.listing-total-heading {
  color: $light_dark_black !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.role-management-heading{
  color: #353535;
font-family: $robotoFont !important;
font-size: 20px !important;
font-style: normal;
font-weight: 600 !important;
line-height: normal;
}
.all-form-label{
  color: #696969;
font-family: $robotoFont;
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 12px; /* 85.714% */
}

.custom-forgotpwd-txt{
  font-family: $robotoFont !important;
  font-weight: 400 !important;
}

.managefeatured-popup {
  color: #4540DB !important;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.actionrequired-txt {
  color: #353535 !important;
  font-family: $robotoFont !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 32px !important;
}

.savedjobpopup-desc {
  color: #353535 !important;
  text-align: center !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.custom-expiringjobs-table {
  color: #4540DB !important;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.custom-expiringjobs-tablebody {
  color: #353535;
  text-align: right;
  font-family: $robotoFont !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
  .custom-table-border {
    border-bottom: none !important;
  }

  .custom-tablecell-border {
    border-bottom: none !important;
  }

  .teammembertxt{
    padding-left: 0px !important;
  }

  @include max_width_devices($mui_md_breakpoint){
    .teammembertxt{
      padding-left: 16px !important;
    }
  }

.jobidinoneline{
  margin-right: -32px !important;
}

.labelTransform {
  font-size: 1rem;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.MuiButton-root{
  &.roboto-font {
    font-family: $robotoFont !important;
  }
}

// Browse more jobs button
.Browse-jobs-font{
  font-size: 14px !important;
  font-family: $robotoFont;
}

// home page search field
.SearchKeywords div{
  margin-top: 8px;
  font-size: 12px !important;
}

.userTypeTxt{
  color: $light_dark_black !important;
}

.compensationActiontoolbar{
  position: absolute;
  right: 0;
}

.breadCrumbWrapper {
  z-index: 1;
  position: relative;
}
.phn-dailog-padding{
  padding: 2px 24px !important;
}

//usaForm
.W-9form-heading{
  font-size: 14px !important;
  margin-top: 20px !important;
}

// file uploader fontsize 
.fileUploadHelper-TextLabel{
  font-size: 13px !important;
  color: #676767 !important;
  font-family: $robotoFont !important;
}
.border-none {
  border: none !important;
  width: 696px !important;
}

.custom-td-height {
  width: 100% !important;
  /* margin-left: 32px !important; */
}
.custom-overflow {
  max-height: 270px !important;
  overflow-x: auto !important;
  margin-top: 16px !important;
}
.custom-td-height tr th {
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.custom-td-height tr td {
  padding: 0 !important;
}
/* .custom-td-height tr td .custom-checkbox {
  padding: 0 9px !important;
} */

.app-bar-box {
  display: flex;
  text-align: center;
  justify-content: center;
}

.app-bar {
  display: inline-flex;
  justify-content: center;
  height: 100px;
  background-color: #0257ab !important;
}

.app-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.app-bar-value {
  display: flex;
  text-align: center;
  justify-content: center;
}

.app-header {
  background-color: #ffffff;
  /* padding: 10px 60px 30px 40px; */
  display: flex;
  justify-content: left;

  /* height: 28px; */
}

.table-box-header {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  /* height: 612px; */
}

.table-box {
  justify-content: center;
}
.custom-table-box1 {
  width: 40%;
  flex-basis: 60% !important;
  margin-right: 80px !important;
}

.custom-text-lft {
  width: 90%;
}

.tbl-grid-box {
  padding: 0;
}

.acc-grid-box {
  /* padding-right: 113px; */
  width: 384px !important;
}

.pagi-text .MuiTablePagination-toolbar {
  /* padding-right: 200px !important; */
}
.custom-select-set {
  width: 100%;
  margin: 5px 0 0 0 !important;
}

.custom-select-set select {
  border: none !important;
  width: 100% !important;
}

.radio-text {
  font-weight: 700 !important;
  font-size: x-large !important;
  color: black !important;
  /* margin-left: 32px !important; */
  margin-top: 24px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #fff;
}
.table-hubspot {
  color: #343434 !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  border-right: 1px solid #dedede;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #0257ab !important;
}

.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: #0257ab !important;
}

.css-s0vvwy-MuiSvgIcon-root {
  margin-right: 25px !important;
}

.css-1xektrd {
  margin-right: 25px !important;
}

.css-1636szt {
  color: #343434 !important;
  padding-right: 20px;
}

.css-1yhq19e {
  color: #343434 !important;
  padding-left: 20px;
}
@media only screen and (max-width: 820px) {
  /* For mobile phones: */
  [class*="tbl-grid-box "] {
   padding: 0;
}
[class*="table-box-header"] {

justify-content: flex-start;

}
[class*="table-box-header"] {

justify-content: flex-start;

}
[class*="custom-table-box1"] {

  max-width: 100% !important;
        flex-basis: 100% !important;
        margin-right: 0px !important;
        width: 100%;
}
[class*="custom-table-box-2"] {

  max-width: 100% !important;
        flex-basis: 100% !important;
        margin-right: 0px !important;
        width: 100%;
}

[class*="table-box"] {

 flex-direction: column!important;
}
  }
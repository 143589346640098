.main-div {
  /* padding-right: 112px !important;     */
  margin: 20px 0;
  background-color: #f6f8fa;
  min-height: 450px !important;
  padding: 10px 20px;
}
.main-div > div {
  margin: 10px 0;
}

.btn-gld {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  background-color: #013365 !important;
  margin-top: 10px !important;
  box-shadow: none !important;
}

.font-gld {
  font-size: 20px !important;
  font-weight: 600 !important;
  padding: 0 0 0 0 !important;
}

.accordion-container {
  margin: 10px 0 !important;
  max-height: 300px !important;
  overflow: auto !important;
}

.btn-box {
  padding: 0 0 10px 0;
}

.Acc-summary {
  padding: 0 0 0 0 !important;
}

.accordion-box {
  display: flow;
  align-items: center;
  border-radius: 6px !important;
  border: 1px solid #dbdbdb;
  background: #fff;
  /* margin-top: 8px; */
}

.box-hdr-txt h5 {
  margin: 0;
}
.MuiAccordion-region .custom-sec-box {
  width: unset;
}

.custom-chkbox {
  margin-left: 0 !important;
  padding: 0 !important;
}

.campaigns-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .campaign-item {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  .custom-table-hd {
    font-size: 1.1em!important;
    font-weight: 700!important;
  }
  .Custom-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
.custom-btn-res{
  display: flex;
          justify-Content: end;
          align-Items: center;
          margin-Top: 1%;
}
.custom-headsearch{
  width: 380px;
}
.custom-hdr{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.custom-hdr>h4{
  font-weight: 600;
  font-size: 1.75em;
  margin-bottom: 0;
}
.custom-srch-btn{
  margin-top: 0!important;
}
  @media only screen and (max-width: 1080px) {
    /* For mobile phones: */
    [class*="Custom-header"] {
      display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
[class*="custom-btn-res"] {
  display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
}
[class*="custom-headsearch"] {
  width: 100%;
}
[class*="custom-headerh5"] {
  margin: 10px 0!important;
  width: 100%;
  display: flex;
}
    }
.custom-header-color> th {
    background-Color: #e8eef3;

}
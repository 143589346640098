body {
  margin: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc !important;
  line-height: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

:root {
  /* fonts */
  --font-open-sans: "Open Sans";
  --font-inherit: inherit;
  --font-source-sans-pro: "Source Sans Pro";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-3xl: 22px;
  --font-size-9xl: 28px;

  /* Colors */
  --color-darkslategray: #343434;
  --color-darkslateblue: #0257ab;

  /* Gaps */
  --gap-21xl: 40px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-13xl: 32px;

  /* Paddings */
  --padding-2xl: 21px;
}

.frame-child {
  position: relative;
  width: 13.41px;
  height: 8.12px;
}
.vector-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 2px;
}
.lorem-ipsum-dolor-sit-amet-parent {
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  gap: 16px;
}
.frame-group,
.vector-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.vector-container {
  padding: 5px 2px;
  transform: rotate(180deg);
  transform-origin: 0 0;
}
.frame-group {
  gap: 1px;
}
.group-child {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #d4eaff;
  width: 42px;
  height: 42px;
}
.at {
  position: absolute;
  top: 8px;
  left: 8px;
  display: inline-block;
  width: 26px;
}
.ellipse-parent {
  position: relative;
  width: 42px;
  height: 42px;
  display: flex;
  text-align: center;
  font-size: 20px;
}
.amanda-thurston {
  margin: 0;
  font-weight: 600;
}
.amandathurstonhotmailcom {
  margin: 0;
  font-size: 16px;
  color: #595959;
}
.contacts-details-container {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: row;
  /* width: 390px; */
  width: 100%;
  flex-shrink: 0;
  font-size: 18px;
}

.contacts-detailss {
  padding-left: 30px;
}
.group-parent {
  /* background-color: #ebeff2; */
  background-color: white;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px 13px;
  gap: 16px;
  width: 100%;
}
.group-item {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #d4ffe8;
  width: 42px;
  height: 42px;
}
.group-container {
  background-color: #f6f8fa;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  gap: 16px;
}
.ellipse-div,
.group-child1,
.group-inner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #ffe9d4;
  width: 42px;
  height: 42px;
}
.ellipse-div,
.group-child1 {
  background-color: #ffd4ee;
}
.group-child1 {
  background-color: #fffbd4;
}
.ew {
  position: absolute;
  top: 8px;
  left: 7px;
}

.frame-container {
  /* background-color: #fff; */
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* justify-content: flex-start; */
  /* gap: 1px; */
  text-align: center;
  font-size: 20px;
}
.frame-inner {
  position: relative;
  width: 12px;
  height: 6px;
}
.nunc-eros-diam-ullamcorper-sit-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.frame-parent,
.frame-wrapper {
  background-color: #fff;
  display: flex;
}
.frame-wrapper {
  border-bottom: 1px solid #dbdbdb;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
}
.cust-frame-parent {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
}
.hubstop-data {
  position: relative;
  line-height: 26px;
}
.hubstop-data-container,
.hubstop-data-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 16px 8px;
}
.hubstop-data-wrapper {
  border-bottom: 1px solid #0257ab;
}
.hubstop-data-container {
  color: #343434;
}
.frame-parent2 {
  position: absolute;
  top: 154px;
  left: 112px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: #0257ab;
}
.available-lists-16 {
  color: #343434;
  margin-top: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}
.group-child3,
.group-child4,
.rectangle-div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #343434;
  width: 22px;
  height: 2px;
}
.group-child3,
.group-child4 {
  top: 8px;
}
.group-child4 {
  top: 16px;
}
.rectangle-parent {
  position: relative;
  width: 22px;
  height: 18px;
}
.group-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 1px;
}
.frame-child4,
.group-icon {
  position: relative;
  width: 50px;
  height: 32px;
}
.frame-child4 {
  width: 27px;
  height: 26px;
}
.frame-parent3,
.group-parent4,
.vector-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}
.frame-parent3,
.group-parent4 {
  align-items: center;
  gap: 16px;
}
.frame-parent3 {
  gap: 32px;
}
.header-child {
  position: relative;
  width: 28px;
  height: 28px;
}
.header,
.mail-review {
  font-size: 24px;
}
.header {
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  gap: 1025px;
  color: #0257ab;
}
.mail-review {
  margin-top: 24px;
  line-height: 26px;
  font-weight: 600;
  color: #000;
}

.praesent-tristique-dictum-turp-wrapper {
  position: absolute;
  top: 934px;
  left: 112px;
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 16px;
  font-size: 18px;
}
.kb {
  position: absolute;
  top: 8px;
  left: 8px;
}
.vestibulum-at-malesuada {
  position: relative;
  font-weight: 600;
}
.thu-26102023-1530 {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #595959;
}
.vestibulum-at-malesuada-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
}
.to-amanda-thurston {
  position: relative;
}
.frame-parent6,
.to-amanda-thurston-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.to-amanda-thurston-parent {
  gap: 4px;
  font-size: 14px;
}
.frame-parent6 {
  gap: 8px;
}
.hi-amanda {
  margin: 0;
}
.hi-amanda-lorem-container {
  position: relative;
  font-size: 14px;
  color: #000;
  display: inline-block;
  width: 100%;
}
.frame-parent4,
.frame-parent5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-parent5 {
  background-color: #fff;
}
.frame-parent4 {
  width: 100%;
  margin-top: 157px;
  gap: 1px;
  max-height: 400px;
  overflow: auto;
}
.group-child8,
.group-child9 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  width: 16px;
  height: 646px;
}
.group-child9 {
  top: 10px;
  left: 4px;
  border-radius: 10px;
  background-color: #c1c1c1;
  width: 8px;
  height: 503px;
}
.group-child11,
.rectangle-group {
  position: absolute;
  top: 255px;
  left: 1312px;
  width: 16px;
  height: 646px;
}
.group-child11 {
  top: 10px;
  left: 4px;
  border-radius: 10px;
  background-color: #c1c1c1;
  width: 8px;
  height: 312px;
}
.rectangle-container {
  position: absolute;
  top: 255px;
  left: 479px;
  width: 16px;
  height: 646px;
}
.mail-review-option-2 {
  display: flex;
  justify-content: center;
}

.MuiList-root .MuiList-padding .css-9pg161-MuiList-root {
  padding-top: 0 !important;
}

.cust-frame-parent-MR {
  /* position: absolute; */
  /* left: 85px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  width: 95% !important;
  margin-left: 25px !important;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  padding: 0 0;
}

.table-pagination .css-zylse7-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0);
}

.table-pagination button {
  min-width: 27px;
  height: 28px;
}

.table-pagination ul button {
  border: 1px solid;
  border-radius: 3px;
  margin: 2px;
}

.search-icon {
  padding: 0 0 0 5px !important;
  border-radius: 5px !important;
  margin-top: 8px !important;
  width: 55px !important;
  background-color: #013365 !important;
}

.search-icon .css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0);
}

.Custom-textfield2 {
  width: 100%;
  overflow: auto;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
}

.Custom-textfield2 input {
  height: 300px;
  width: 100%;
  overflow: auto;
}

.search-text-field {
  width: 500px !important;
  border-radius: 15px !important;
  margin-top: 8px !important;
}

.custom-img {
  width: 35px !important;
  border-radius: 6px !important;
}

.custom-list-mailReview {
  max-height: 420px !important;
  background-color: white !important;
  overflow: auto !important;
}

.custm-ListItemButton {
  padding: 0 !important;
}

.custom-sec-box-mailReview {
  height: auto !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  box-shadow: 0 !important;
}

.accordion-box-mailReview {
  display: flow;
  align-items: center;
  border-radius: 0px !important;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  border-left: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;

  background: #fff;
  width: 99%;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 16px 16px !important;
}

.custom-second-loader {
  margin-top: 30%;
}
.cust-sublist{
  width: 98%;
}
@media only screen and (max-width: 1080px) {
  /* For mobile phones: */
  [class*="Custom-header"] {
    display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}


[class*="cust-sublist"] {
  width: 45vw!important;
}

  }

  @media only screen and (max-width: 520px) {
    /* For mobile phones: */
   
  
  
  [class*="cust-sublist"] {
    width: 75vw!important;
  }
  [class*="frame-parent4"] {
   margin-top: 0!important;
  }
  
    }